import { CreditToolApi } from "@credit-tool/api";
import { triggerEvent, errorHandler } from "@host";

// import { ProductType } from "@model/als";
import { ProductType } from "@graphql/schema";

import { getAsyncActionCreator } from "../creators";
import { rootSelectors } from "../selectors";

const asyncCreator = getAsyncActionCreator(rootSelectors);

export const partialLeadActions = asyncCreator({
    async submitPartialLead(_: null, models) {
        const {
            widgetId: dealerId,
            widget,
        } = models.dealer.state;
        const { widgetId: distId } = models.user.state.distCompany || {};
        const input = rootSelectors.getPartialPayload(null, models);
        const widgetId = distId || dealerId;

        try {
            const result = await CreditToolApi.submitPartial({
                widgetId,
                input,
                product: ProductType.CreditTool,
            });
            triggerEvent({ event: "PartialLeadSubmit" });

            if (result.submitLead?.leadId) {
                models.user.update.leadId(result.submitLead.leadId);
            }
        } catch (err) {
            throw new errorHandler.ExtendError(err, {
                widgetId,
                input,
                product: ProductType.CreditTool,
                widgetDetails: widget,
            });
        }
    },
});