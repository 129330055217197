import { h } from "preact";
import { useEffect, useMemo, useRef, useCallback, useState } from "preact/hooks";
import clsx from "clsx";

import { Text, Title } from "@components/Text";
import { Button, IButtonProps } from "@components/Button";
import { FeedbackCapture } from "@containers/feedback/FeedbackCapture";

import { HookFunction } from "@app-modal";
import { Overlay } from "@app-modal/Modal/Overlay";

import { frameHandler } from "@host";

import type { RetentionView } from "./types";

import GrimaceEmoji from "@assets/emoji-grimace.png";
import styles from "./BaseRetention.module.scss";

export interface IButtonPropsList extends IButtonProps {
    label: string;
    className?: string;
}

export interface IBaseRetentionProps {
    title: string;
    subtitles: string[];
    buttonsProps: IButtonPropsList[];
    /**
     * Displays the feedback capture component
     * if set to `true` and hides it otherwise.
     * Defaults to `false`
     * */
    feedback?: boolean;
}

type OnClickFunction = (e: h.JSX.TargetedEvent<HTMLElement, MouseEvent>) => void

export const BaseRetention: RetentionView<IBaseRetentionProps> = ({
    title,
    subtitles,
    buttonsProps,
    feedback = false,
    closeModal,
    useModalHook,
    submitFeedback,
}) => {
    const [showRetentionOverlay, setRetentionOverlay] = useState(false);
    const closeBlocked = useRef(false);
    const contentRef = useRef<HTMLDivElement>(null);

    const closeModalListener = useCallback<HookFunction>((actions) => {
        if (closeBlocked.current) return;
        closeBlocked.current = true;
        setRetentionOverlay(true);
        return actions.block;
    }, []);
    useModalHook("close", closeModalListener);

    useEffect(() => {
        if (showRetentionOverlay) {
            requestAnimationFrame(() => {
                if (contentRef.current) {
                    frameHandler.scrollTo(contentRef.current);
                }
            });
        }
    }, [showRetentionOverlay]);


    const closeOverlay = () => {
        closeBlocked.current = false;
        setRetentionOverlay(false);
    };

    const subtitlesContent = useMemo(() => {
        return subtitles.map((subtitle) => (
            <Text className={styles.subtitle}>
                {subtitle}
            </Text>
        ));
    }, [subtitles]);

    const buttons = useMemo(() => {
        const getNavigate = (cb: OnClickFunction): OnClickFunction => (
            (e) => {
                cb(e);
                closeOverlay();
            }
        );
        return buttonsProps.map((props) => (
            <Button
                {...props}
                className={clsx(styles.button, props.className)}
                onClick={getNavigate(props.onClick)}
            >
                {props.label}
            </Button>
        ));
    }, [buttonsProps]);

    return (
        <Overlay open={showRetentionOverlay} noAutoScroll>
            <div className={styles.mainContainer} ref={contentRef}>
                <div className={styles.content}>
                    <div className={styles.emojiContainer}>
                        <img src={GrimaceEmoji} />
                    </div>
                    <div className={styles.titleContainer}>
                        <Title big block className={styles.title}>
                            {title}
                        </Title>
                    </div>
                    <div className={styles.subtitlesContainer}>
                        {subtitlesContent}
                    </div>
                    <div className={styles.buttonsContainer}>
                        {buttons}
                    </div>
                    {feedback && <FeedbackCapture submitFeedback={submitFeedback} />}
                    <button className={styles.closeBtn} onClick={closeModal}>
                        Close Anyway
                    </button>
                </div>
            </div>
        </Overlay>
    );
};