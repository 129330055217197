import { transformFromPhone } from "@util/phone";
import { rawPostalCode, dateToRaw } from "@util/transforms";

import type { CreditAuthInput } from "@graphql/schema";
import type { ICombinedState } from "@msa-tool/state";

import { isNil, omitBy } from "lodash";

export const mapStateToAuth = (state: ICombinedState): CreditAuthInput => {
    const partialLead: CreditAuthInput = {
        id: state.leadId || undefined,
        firstName: state.firstName,
        lastName: state.lastName,
        dateOfBirth: dateToRaw(state.dateOfBirth),
        phoneNumber: transformFromPhone(state.phoneNumber),
        emailAddress: state.emailAddress,
        street: state.street,
        streetNumber: state.streetNumber,
        unit: state.unit,
        city: state.city,
        postalCode: rawPostalCode(state.postalCode),
        province: state.province,
        country: state.country,
        sourceUrl: state.sourceUrl || undefined,
        dealerCreditConsent: state.dealerCreditConsent,
        campaign: state.campaign || undefined,
    };

    return omitBy(partialLead, isNil) as any as CreditAuthInput;
};

export type MappedAuthPayload = ReturnType<typeof mapStateToAuth>;