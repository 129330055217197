import snakeCase from "lodash/snakeCase";
import { frameHandler } from "@host";
import { Company } from "@model/company";
import { AllProducts } from "@model/als";

export const getMembersWithProduct = (
    memberCompanies = [] as (Company | undefined)[],
    product?: AllProducts,
): Company[] => {
    const checkProduct = product
        ? snakeCase(product).toUpperCase()
        : frameHandler.selectedProduct?.productType;

    return (
        memberCompanies
            .filter((c) => (
                c &&
                !c.archived &&
                c.products?.some((p) => (
                    p === checkProduct
                ))
            ))
    ) as Company[] || [];
};

export const getMembersWithProducts = (
    memberCompanies = [] as (Company | undefined)[],
    products: AllProducts[],
): Company[] => {
    const formattedProducts = products.map((product) => snakeCase(product).toUpperCase());
    const checkProducts = formattedProducts.length
        ? formattedProducts
        : frameHandler.selectedProduct?.productType;

    return (
        memberCompanies
            .filter((c) => (
                c &&
                !c.archived &&
                c.products?.some((p) => (
                    checkProducts?.includes(p)
                ))
            ))
    ) as Company[] || [];
};