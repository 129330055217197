import { h, Fragment, FunctionalComponent, ComponentProps } from "preact";
import { useState } from "preact/hooks";

import { Section } from "@app-modal";
import { TradeLines, Inquiries, Meter } from "@containers/credit-report";
import { MSAToolRetention } from "@overlays/retention/msa-tool";

import { Link } from "@components/Link";
import { Excerpt } from "@components/Excerpt";
import { TitleBlock } from "@components/TitleBlock";
import { Card } from "@components/Card";
import { GridItem, Grid } from "@components/Grid";
import { Text, Title } from "@components/Text";
import { NoSsr } from "@components/NoSsr";

import { useMSAToolModels } from "@msa-tool/state";

import styles from "./Score.module.scss";
import equifaxLogo from "@assets/equifax-logo.png";
// import InfoIcon from "@assets/info-icon.svg";
// import EmailIcon from "@assets/email-icon.svg";
// import PrintIcon from "@assets/print-icon.svg";

import type { MSAToolViewComponent } from "@msa-tool";
import type { AnswerMutation } from "@graphql/schema";

type CreditReport = NonNullable<AnswerMutation["creditQuery"]>["report"];

interface ICreditScore extends Omit<NonNullable<CreditReport>, "score"> {
    score: (
        NonNullable<
            NonNullable<
                NonNullable<
                    CreditReport
                >["score"]
            >["value"]
        >
    );
    likelihood: string;
}

const likelihoodMap: [number, string][] = [
    [650, "High"],
    [0, "Probable"],
];

const getLikelihood = (score: number): string => (
    (
        likelihoodMap.find(([val]) => score >= val) ||
        likelihoodMap.slice(-1)[0]!
    )[1]
);

const getCreditReport = (
    creditReport: CreditReport,
): ICreditScore | null => {
    const score = creditReport?.score?.value;
    const inquiries = creditReport?.inquiries || [];
    const trades = creditReport?.trades || [];
    if (typeof score !== "number") {
        return null;
    }

    return {
        score,
        inquiries,
        trades,
        likelihood: getLikelihood(score),
    };
};

interface ISeeMoreProps extends Pick<
    ComponentProps<MSAToolViewComponent>,
    | "navigateTo"
> {}

const SeeMore: FunctionalComponent<ISeeMoreProps> = ({ navigateTo }) => (
    <div className={styles.seeMoreContainer}>
        <Link
            label="See more"
            className={styles.seeMoreLink}
            onClick={() => navigateTo("score-details")}
        />
    </div>
);

export const CustomScore: MSAToolViewComponent = (viewProps) => {
    const {
        showError,
        navigateTo,
        goToNext,
    } = viewProps;

    const {
        msaTool: {
            useSelector,
        },
    } = useMSAToolModels();
    const report = useSelector("report");
    const creditQuery = useSelector("query");
    const [creditReport] = useState(() => getCreditReport(report) || {} as ICreditScore);
    const noHit = (creditReport?.score || 0) <= 0;

    if (process.browser) {
        if (creditQuery?.equifaxServerError) {
            navigateTo("equifax-down");
            return null;
        }
        if (creditReport.score === undefined) {
            showError("Invalid Credit Score", {
                extra: {
                    report,
                },
            });
            return null;
        }
    }

    return (
        <Fragment>
            <TitleBlock
                title="Thank you!"
                titleClass={styles.title}
            />
            <NoSsr>
                <Section grid columns={2} className={styles.gridColumns}>
                    <GridItem fullWidth>
                        <div className={styles.summary}>
                            <Text style={{ paddingBottom: "15px" }} block>
                                Your application has been submitted and our approval centre will be in touch shortly.
                            </Text>
                        </div>
                    </GridItem>
                    <GridItem fullWidth>
                        <div>
                            <Meter score={creditReport.score} noHit={noHit} />
                        </div>
                    </GridItem>
                    <GridItem fullWidth>
                        <div>
                            <Text block className={styles.equifaxSubtitle}>
                                <span>
                                    Data provided by
                                    <img src={equifaxLogo} style={{
                                        paddingLeft: "2px",
                                        paddingRight: "2px",
                                    }} />
                                    Canada
                                </span>
                            </Text>
                            <Excerpt
                                block
                                className={styles.equifax}
                                excerpt="Equifax is a registered Canadian credit bureau that maintains your Canadian consumer credit file, which has been used here, as permitted by you, to"
                                complete="Equifax is a registered Canadian credit bureau that maintains your Canadian consumer credit file, which has been used here, as permitted by you, to provide you with educational content contained within your Equifax Consumer Credit Report. This is not your full Equifax Consumer Credit report. For a full copy of your up to date Equifax credit file, please contact Equifax directly. The information contained herein is current as of the date indicated"
                            />
                        </div>
                    </GridItem>
                    {!noHit && (
                        <>
                            <Card className={styles.scoreDetails}>
                                <Inquiries
                                    inquiries={creditReport.inquiries}
                                    numEntries={3}
                                    action={(
                                        <SeeMore navigateTo={navigateTo} />
                                    )}
                                />
                            </Card>
                            <Card className={styles.scoreDetails}>
                                <TradeLines
                                    tradeLines={creditReport.trades}
                                    numEntries={2}
                                    action={(
                                        <SeeMore navigateTo={navigateTo} />
                                    )}
                                />
                            </Card>
                        </>
                    )}
                </Section>
            </NoSsr>
            <MSAToolRetention
                {...viewProps}
                page="score"
                feedback
            />
        </Fragment>
    );
};