import { h, FunctionalComponent, Fragment } from "preact";

export const NoSsr: FunctionalComponent = ({ children }) => {
    if (!process.browser) return null;

    return (
        <Fragment>
            {children}
        </Fragment>
    );
};