import {
    createNewClient,
    QueryType,
    QueryDefs,
} from "@api/api-client";
import {
    AuthMutation,
    AuthMutationVariables,
    AnswerMutation,
    AnswerMutationVariables,
    SubmitLoanAppMutation,
    SubmitLoanAppMutationVariables,
    SubmitPartialLeadMutation,
    SubmitPartialLeadMutationVariables,
} from "@graphql/schema";
import {
    GetCreditAuth,
    GetCreditAnswer,
    SubmitLoanApp,
    SubmitPartialLead,
} from "./query";

type CreditApi = QueryDefs<{
    getAuth: QueryType<AuthMutation, AuthMutationVariables>;
    getAnswer: QueryType<AnswerMutation, AnswerMutationVariables>;
    submitLoanApp: QueryType<
        SubmitLoanAppMutation,
        SubmitLoanAppMutationVariables
    >;
    submitPartial: QueryType<
        SubmitPartialLeadMutation,
        SubmitPartialLeadMutationVariables
    >
}>;

const hasMockData: (keyof CreditApi)[] = [
    "getAuth",
    "getAnswer",
];

export const MSAToolApi = createNewClient<CreditApi>({
    getAuth: GetCreditAuth,
    getAnswer: GetCreditAnswer,
    submitLoanApp: SubmitLoanApp,
    submitPartial: SubmitPartialLead,
}, {
    critical: {
        getAnswer: false,
    },
    fetchMock: MOCK ? async (name, profile, actions) => {
        if (!hasMockData.includes(name)) {
            return {};
        }
        return await import(`./__fixtures/${name}${profile}.json`);
    } : undefined,
});

// export const MSAToolApi = new (
//     class extends ApiClient {
//         public getAuth: SdkMethod<"auth"> = async ({ input, widgetId }) => {
//             return await this.callApi("auth", {
//                 widgetId,
//                 input,
//             });
//         }

//         public getAnswer: SdkMethod<"answer"> = async (input) => {
//             return await this.callApi("answer", input, false);
//         }

//         public submitLoanApp: SdkMethod<"submitLoanApp"> = async (input) => {
//             return await this.callApi("submitLoanApp", input);
//         }

//         public submitPartial: SdkMethod<"submitPartialLoad"> = async ({ input, widgetId }) => {
//             return await this.callApi("submitPartialLoad", {
//                 widgetId,
//                 input,
//             });
//         }
//     }
// )({
//     mockProduct: "msaTool",
// });