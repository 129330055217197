import { h, FunctionalComponent } from "preact";
import clsx from "clsx";
import styles from "./Spinner.module.scss";

interface ISpinnerProps {
    className?: string;
}

export const Spinner: FunctionalComponent<ISpinnerProps> = ({ className }) => {
    return <div className={clsx(styles.spinner, className)} />;
};