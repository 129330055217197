import { gql } from "@api/api-client";

export const GetCreditAnswer = gql`
    mutation answer($input: CreditQueryInput!) {
        creditQuery(input: $input) {
            transactionKey
            equifaxServerError
            equifaxErrorMessages
            fraudCheckFailed
            interactiveQuery {
                queryId
                questions {
                    questionId
                    questionText
                    answers {
                        answerId
                        value
                    }
                }
            }
            report {
                score {
                    value
                }
                trades {
                    # Collapsed
                    creditor {
                        name
                    }
                    # Collapsed & Expanded
                    dateOpened # Date Opened
                    balanceAmount # Balance
                    paymentTermAmount # Payment Amount

                    # Overview
                    ## Balance
                    ## Date Opened
                    association # Account Type
                    portfolioType {
                        code
                        description
                    } # this.code + paymentRate.code = Loan Status
                    paymentRate {
                        code
                        description
                    } # portfolioType.code + this.code = Loan Status
                    highCreditAmount # High Credit Amount
                    dateLastActivityOrPayment # Last Activity
                    monthsReviewed # Months Reviewed

                    # Payment Information
                    ## Payment Amount
                    paymentTerm # Payment Term
                    lastSlowPayment # Last Slow Payment

                    # Slow Payment Counters
                    derogatoryCounters {
                        past30
                        past60
                        past90
                    }
                }
                inquiries {
                    date
                    inquirer {
                        id
                        name
                        phone
                    }
                }
            }
        }
    }
`;