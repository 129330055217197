import { h, FunctionalComponent } from "preact";
import clsx from "clsx";
import { Text, ITextProps } from "../Text";

import styles from "./Title.module.scss";

interface ITitleProps extends ITextProps {
    big?: boolean;
}

export const Title: FunctionalComponent<ITitleProps> = ({ children, className, big = false, ...props }) => (
    <Text
        className={clsx(
            big ? styles.bigTitle : styles.title,
            className,
        )}
        {...props}
    >
        {children}
    </Text>
);