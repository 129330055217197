import { useRouter as useNextRouter, NextRouter } from "next/router";
import { useMemo, useRef, useCallback, useEffect } from "preact/hooks";
import { createQueryString, getQueryParams } from "@util/query";
import {
    Sentry,
    frameHandler,
    frameMeta,
    triggerEvent,
    ga,
    mfq,
} from "@host";

import type { ITriggerEventContext } from "@shared/types";
import type { RouteMapping } from "@model/routes";
import type { UrlObject } from "url";

interface IRoutingUtils<T extends RouteMapping<any, any>> {
    prefetchRoutes: (newRoute: string, root?: boolean) => void;
    getFullRoute: (subRoute: keyof T["routes"]) => string;
    getSubRoute: (pathname: string) => string;
    getRouteMeta: (subRoute?: keyof T["routes"]) => Required<T["routes"]>[keyof T["routes"]];
}

const getRouteMapUtils = <T extends RouteMapping<any, any>>(routeMap: T) => {
    let router: NextRouter;
    let utils: IRoutingUtils<T>;

    return (nextRouter: NextRouter): IRoutingUtils<T> => {
        if (nextRouter === router) return utils;

        utils = {
            prefetchRoutes(pathname: string, root = false) {
                const routeMeta = utils.getRouteMeta(
                    this.getSubRoute(pathname),
                );
                if (!routeMeta) return;

                const prefetchRoutes = root
                    ? [routeMeta.target || pathname]
                    : [routeMeta.next ?? ""]
                        .concat(routeMeta.prefetch || [])
                        .filter(Boolean)
                        .map(this.getFullRoute);

                prefetchRoutes.forEach((route) => {
                    if (RUNTIME_DEBUG) console.log("Prefetching:", route);
                    nextRouter.prefetch(route);
                });
            },
            getFullRoute(subRoute) {
                const routeMeta = utils.getRouteMeta(subRoute);
                if (routeMeta?.target) {
                    return routeMeta.target;
                }
                const base = routeMap.base;
                return `${base}/${subRoute}`;
            },
            getSubRoute(pathname) {
                return pathname.replace(
                    new RegExp(`^${routeMap.base}/`),
                    "",
                );
            },
            getRouteMeta(subRoute) {
                return routeMap.routes[
                    (subRoute as string) || this.getSubRoute(nextRouter.pathname)
                ] as T["routes"][keyof T["routes"]];
            },
        };

        return utils;
    };
};

interface IUseRouterOptions {
    prefetchAll?: boolean;
}

type NavigateFn<T> = (
    route: T,
    option?: INavigateToOptions,
) => void

export interface INavigateToOptions {
    context?: Record<string, string>;
    query?: Record<string, string>;
    remove?: string[];
    track?: boolean;
    done?: VoidFunction;
    sub?: boolean;
}

export const useRouter = <T extends RouteMapping<any, any>>(
    routeMap: T,
    options = {} as IUseRouterOptions,
) => {
    const nextRouter = useNextRouter();
    const curOptions = useRef(options);
    curOptions.current = options;

    if (process.browser && RUNTIME_DEBUG) (window as any).router = nextRouter;

    const getUtils = useCallback(() => getRouteMapUtils(routeMap), [routeMap]);

    useEffect(() => {
        const utils = getUtils()(nextRouter);

        const { prefetchAll } = curOptions.current;

        if (prefetchAll) {
            Object.keys(routeMap.routes).forEach((subRoute) => {
                utils.prefetchRoutes(subRoute, true);
            });
        } else {
            utils.prefetchRoutes(nextRouter.pathname);
        }

    }, [nextRouter, getUtils, routeMap]);

    const router = useMemo(() => {
        const utils = getUtils()(nextRouter);

        const navigateTo: NavigateFn<string> = (
            route,
            {
                done,
                context = {},
                query = {},
                remove = [],
                track = true,
                sub = true,
            } = {},
        ) => {
            if (!route) return;

            const lastRoute = nextRouter.pathname;
            const newRoute = sub
                ? utils.getFullRoute(route)
                : route as string;

            const newRouteObj: UrlObject = {
                pathname: newRoute,
                query: context,
            };

            if (RUNTIME_DEBUG) {
                console.log("Last route:", lastRoute, nextRouter);
                console.log("New route:", newRouteObj);
            }

            const updateQuery = getQueryParams(query);
            remove.forEach((key) => {
                if (updateQuery.hasOwnProperty(key)) {
                    delete updateQuery[key];
                }
            });

            const newQuery = createQueryString(updateQuery);
            const asPath = `${location.pathname}?${newQuery}`;
            // if (RUNTIME_DEBUG) {
            //     console.log("As route:", asPath);
            // }

            const handleRoutingComplete = (...args: any[]) => {
                // console.log("handleRoutingComplete args:", args);
                if (done) done();

                if (track) ga.trackView(newRoute, lastRoute);

                const eventContext: ITriggerEventContext | undefined = (
                    context.product ? {
                        productType: context.product,
                    } : undefined
                );
                triggerEvent(
                    {
                        event: "pageView",
                        context: {
                            from: lastRoute,
                            to: newRoute,
                        },
                    },
                    true,
                    eventContext,
                );
                mfq.pageView(newRoute);
                nextRouter.events.off("routeChangeComplete", handleRoutingComplete);
            };

            nextRouter.events.on("routeChangeComplete", handleRoutingComplete);

            nextRouter.replace(newRouteObj, asPath);
            Sentry.addBreadcrumb({
                category: "navigation",
                level: Sentry.Severity.Info,
                data: {
                    from: lastRoute,
                    to: newRoute,
                    manual: true,
                },
            });
        };

        return {
            nextRouter,
            navigateTo: navigateTo as NavigateFn<keyof T["routes"]>,
            get curRoute() {
                return nextRouter.pathname;
            },
            get hasBack() {
                return !!utils.getRouteMeta()?.back;
            },
            backToLaunch(cb?: () => void) {
                const remove: string[] = [];

                if (!frameMeta.embedded) {
                    remove.push("product");
                    frameHandler.product = undefined;
                }

                navigateTo("/", {
                    remove,
                    track: false,
                    done: cb,
                    sub: false,
                });
            },
            next(cb?: () => void) {
                const desiredRoute = utils.getRouteMeta()?.next;
                if (desiredRoute) {
                    return navigateTo(desiredRoute, {
                        track: true,
                        done: cb,
                    });
                }
            },
            back(cb?: () => void) {
                const desiredRoute = utils.getRouteMeta()?.back;
                if (typeof desiredRoute === "string") {
                    return navigateTo(desiredRoute, {
                        track: true,
                        done: cb,
                    });
                }
                if (typeof desiredRoute === "boolean" && desiredRoute === true) {
                    const allRoutes = Object.entries(routeMap.routes);
                    const curRoute = utils.getSubRoute(nextRouter.pathname);
                    const curRouteIdx = allRoutes.findIndex(([route]) => route === curRoute);
                    if (curRouteIdx > 0) {
                        const backRoute = (allRoutes[curRouteIdx - 1] || [])[0];
                        return navigateTo(backRoute!);
                    }
                }
            },
        };
    }, [getUtils, nextRouter, routeMap.routes]);

    return router;
};

export type ModalRouter = ReturnType<typeof useRouter>;