import { h, FunctionalComponent, Fragment } from "preact";
import { useState } from "preact/hooks";
import clsx from "clsx";

import { Text, Title } from "@components/Text";

import { getMonthName } from "@util/date";
import { Gauge } from "./Gauge";
import styles from "./Meter.module.scss";

import poorIcon from "@assets/status-poor.png";
import fairIcon from "@assets/status-fair.png";
import goodIcon from "@assets/status-good.png";
import excellentIcon from "@assets/status-excellent.png";
import disappointed from "@assets/emoji-disappointed.png";
import { getCreditStatus, maxCreditScore, CreditStatusDescriptors } from "@util/credit";

interface IMeterProps {
    score: number;
    noHit: boolean;
}

const colorMap: [number, string][] = [
    [650, "#16B47A"],
    [0, "#FFB736"],
];

const statusIcons: Record<CreditStatusDescriptors, string> = {
    "Rebuilding": poorIcon,
    "Fair": fairIcon,
    "Good": goodIcon,
    "Excellent": excellentIcon,
};

const getColor = (score: number): string => {
    const color = colorMap.find(([val]) => score >= val);
    return (color || [])[1] || "#000000";
};

interface IStatusIconProps {
    status: string;
}
const StatusIcon: FunctionalComponent<IStatusIconProps> = ({ status }) => {
    if (!status || !(status in statusIcons)) return null;
    return (
        <img src={statusIcons[status as CreditStatusDescriptors]} />
    );
};

export const Meter: FunctionalComponent<IMeterProps> = ({
    score,
    noHit = false,
}) => {
    const realScore = score >= maxCreditScore ? maxCreditScore : score;
    const [status] = useState(() => getCreditStatus(realScore || 0));
    const [hue] = useState(() => getColor(realScore));
    const [today] = useState(() => {
        const dt = new Date();
        const month = getMonthName(dt);
        const year = dt.getFullYear();
        return `${month} ${year}`;
    });
    const noHitStyle = noHit ? styles.noHit : "";

    return (
        <div className={clsx(styles.container, noHitStyle)}>
            {!noHit ? (
                <Fragment>
                    <div className={styles.gauge}>
                        <Gauge score={realScore} />
                    </div>

                    <div className={styles.detailContainer}>
                        <div className={styles.details}>
                            <Title block big className={styles.scoreTitle}>{"Your score is"}</Title>
                            <Title className={styles.score} block big>
                                {score}
                            </Title>
                            <Title
                                className={styles.status}
                                style={{ color: hue }}
                                block
                                big
                            >
                                {status}
                            </Title>
                            <Text className={styles.scoreDate} block>
                                {today}
                            </Text>
                        </div>
                        <div className={styles.statusIcon}>
                            <StatusIcon status={status} />
                        </div>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    <div className={clsx(styles.statusIcon, noHitStyle)}>
                        <img src={disappointed} />
                    </div>
                    <Title big block>{"Not enough credit to rate"}</Title>
                    <Title block className={styles.subTitle}>{"No score returned"}</Title>
                    <Title className={styles.scoreDate} block>
                        {today}
                    </Title>
                </Fragment>
            )}
        </div>
    );
};