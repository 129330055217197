
import { mapStateToAuth, mapStateToCreditApp, mapStateToPartialLead } from "../mapping";
import { createRootSelectors } from "../creators";

const stateSelectors = createRootSelectors({
    combinedState(_: null, models) {
        return {
            ...models.dealer.state,
            ...models.creditTool.state,
            ...models.user.state,
        };
    },
});

const calculatedSelectors = createRootSelectors({
    getAuthPayload(_: null, models) {
        const state = stateSelectors.combinedState(null, models);
        return mapStateToAuth(state);
    },
    getCreditAppPayload(_: null, models) {
        const state = stateSelectors.combinedState(null, models);
        return mapStateToCreditApp(state);
    },
    getPartialPayload(_: null, models) {
        const state = stateSelectors.combinedState(null, models);
        const { location } = models.datalayer.select.location();
        return mapStateToPartialLead({ ...state, additionalInformation: { stockId: location?.stockId } });
    },
});

export const rootSelectors = {
    ...stateSelectors,
    ...calculatedSelectors,
};
