import { createSelectors } from "../creators";
import { getEidQuery } from "@credit-tool/eid";

const eidSelectors = createSelectors({
    eidQuery(_: null, state) {
        return getEidQuery(state.query);
    },
});

export const modelSelectors = {
    ...eidSelectors,
};