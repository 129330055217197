import { getCreators, getRootCreators } from "@autocorp/redux";
import type { IMSAToolState, CombinedModels } from "./index";

export const {
    createSelectors: createRootSelectors,
    getAsyncActionCreator,
} = getRootCreators<CombinedModels>();

export const {
    createSelectors,
} = getCreators<IMSAToolState>();