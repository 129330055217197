import { attachModel, getCombinedModels, hydrateModel } from "@store";
import { MSAToolModel, IMSAToolState, ICombinedState } from "./model";
import { rootSelectors } from "./selectors";
import { combinedRootActions } from "./actions";

export const msaToolModels = {
    MSAToolModel,
};

export const combinedModels = getCombinedModels(msaToolModels);
export type CombinedModels = typeof combinedModels;

const store = attachModel(msaToolModels, {
    selectors: rootSelectors,
    asyncHandlers: combinedRootActions,
    events: {
        postInit: async (instance) => {
            // instance.model.msaTool.update.report(
            //     require("../__fixtures/getAnswer1.json").creditQuery.report,
            // );
            await hydrateModel(MSAToolModel, instance);
        },
    },
});

export type { IUserState } from "@store";
export type { IMSAToolState, ICombinedState };

export const useMSAToolStore = () => store.useStore();
export const useMSAToolModels = () => store.useModel();