import { h, FunctionalComponent } from "preact";
import { Text } from "@components/Text";
import { Spinner } from "@components/Spinner";
import styles from "./Loading.module.scss";

export const Loading: FunctionalComponent = () => {
    return (
        <div className={styles.container}>
            <div className={styles.loading}>
                <Spinner />
                <Text className={styles.loading}>Validating information...</Text>
            </div>
        </div>
    );
};