import { useRef, useEffect } from "preact/hooks";
import { addResizeListener, ResizeListener } from "@util/resize";

export const useResizeListener = <T extends HTMLElement = HTMLElement>(
    cb: (width: number, height: number) => void,
    condition: () => boolean = () => true,
) => {
    const ref = useRef<T | null>(null);
    const reportChange = useRef(cb);
    const conditionRef = useRef(condition);
    useEffect(() => {
        const el = ref.current;
        if (!el) return;

        const addListener = conditionRef.current();
        if (!addListener) return;

        const listener: ResizeListener = (resized) => {
            reportChange.current(resized.offsetWidth, resized.offsetHeight);
        };

        const remove = addResizeListener(el, listener);
        listener(el);
        return remove;
    }, []);

    return ref;
};