import { useEffect, useRef } from "preact/hooks";
import { frameHandler } from "@host";
import type { Commands, CommandHandler } from "@host/frame";

export const useFrameListener = <T extends Commands>(
    event: T,
    listener: CommandHandler<T>,
    condition: () => boolean = () => true,
) => {
    const getCondition = useRef(condition);
    useEffect(() => {
        // console.log("useFrameListener:", getCondition.current());
        if (getCondition.current()) {
            frameHandler.on(event, listener);
            return () => {
                frameHandler.off(event, listener);
            };
        }
    }, [event, listener]);
};