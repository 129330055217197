import { h, FunctionalComponent, Fragment, VNode } from "preact";
import { useMemo } from "preact/hooks";
import { Text, Title } from "@components/Text";

import styles from "./Inquiries.module.scss";

import type { CreditInquiries } from "../types";

interface IInquiriesProps {
    inquiries: CreditInquiries;
    /** Indicates the number of entries to display. If undefined, all entries are shown. */
    numEntries?: number;
    action?: VNode;
}

export const Inquiries: FunctionalComponent<IInquiriesProps> = ({
    inquiries,
    numEntries,
    action,
}) => {
    const inquiryEntries = useMemo(() => (
        inquiries.slice(0, numEntries)
    ), [inquiries, numEntries]);
    return (
        <Fragment>
            <div className={styles.header}>
                <Title>Credit Inquiries</Title>
                <Text block>(Past 30 Days)</Text>
            </div>
            <div className={styles.entries}>
                {inquiryEntries.map((inquiry, idx) => (
                    <div className={styles.detailEntry} key={`inquiry-${idx}`}>
                        <div className={styles.detailTitle}>
                            {inquiry.inquirer?.name || "Unknown Inquirer"}
                        </div>
                        <Text block>
                            Inquiry made: {inquiry.date}
                        </Text>
                    </div>
                ))}
            </div>
            {inquiries.length > 0 && action}
        </Fragment>
    );
};