import { h, FunctionalComponent, JSX } from "preact";
import { Grid, IGridProps } from "@components/Grid";

interface ISectionProps extends Pick<IGridProps, "columns"> {
    className?: string;
    grid?: boolean;
    top?: number;
    bottom?: number;
}
export interface ISectionFC extends FunctionalComponent<ISectionProps> {
    displayName: "section";
}

export const Section: ISectionFC = ({ children, className, top = 0, bottom = 0, grid, columns }) => {
    const styles: JSX.CSSProperties = {};
    if (top) {
        styles.paddingTop = `${top}px`;
    }
    if (bottom) {
        styles.paddingBottom = `${bottom}px`;
    }

    return (grid
        ? <Grid style={styles} className={className} columns={columns ?? 1}>{children}</Grid>
        : <div style={styles} className={className}>{children}</div>
    );
};

Section.displayName = "section";