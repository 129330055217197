import type {
    ICreditTradeLineModel,
    CreditTradeLineEntry,
    ICreditTradeLineField,
} from "./types";
import { toCurrency } from "@util/transforms";
import get from "lodash/get";
import dayjs from "dayjs";

const formatDate = (val: string): string => dayjs(val).format("MMM YYYY");

export const creditTradeLineMap: ICreditTradeLineModel[] = [
    {
        default: true,
        group: "creditor.name",
        fields: [
            {
                field: "dateOpened",
                title: "Date Opened",
                format: formatDate,
            },
            {
                field: "paymentTermAmount",
                title: "Payment Amount",
                format: toCurrency,
            },
            {
                field: "balanceAmount",
                title: "Balance",
                format: toCurrency,
            },
        ],
    },
    {
        default: false,
        group: "Overview",
        fields: [
            {
                field: "balanceAmount",
                title: "Balance",
                format: toCurrency,
            },
            {
                field: "dateOpened",
                title: "Date Opened",
                format: formatDate,
            },
            {
                field: "association",
                title: "Account Type",
            },
            {
                field: ["portfolioType.code", "paymentRate.code"],
                title: "Loan Status",
            },
            {
                field: "highCreditAmount",
                title: "High Credit Amount",
                format: toCurrency,
            },
            {
                field: "dateLastActivityOrPayment",
                title: "Last Activity",
                format: formatDate,
            },
            {
                field: "monthsReviewed",
                title: "Months Reviewed",
            },
        ],
    },
    {
        default: false,
        group: "Payment Information",
        fields: [
            {
                field: "paymentTermAmount",
                title: "Payment Amount",
                format: toCurrency,
            },
            {
                field: "paymentTerm",
                title: "Payment Term",
                format: (val) => val || "N/A",
            },
            {
                field: "lastSlowPayment",
                title: "Last Slow Payment",
            },
        ],
    },
    {
        default: false,
        group: "Slow Payment Counters",
        fields: [
            {
                field: "derogatoryCounters.past30",
                title: "Past 30",
            },
            {
                field: "derogatoryCounters.past60",
                title: "Past 60",
            },
            {
                field: "derogatoryCounters.past90",
                title: "Past 90",
            },
        ],
    },
];

export const getTradeLineField = (tradeLine: CreditTradeLineEntry, { field, title, format }: ICreditTradeLineField): [string, string] => {
    const rawVal = get(tradeLine, field);
    const safeVal = typeof rawVal === "number"
        ? rawVal.toString()
        : rawVal;
    const formatted = safeVal !== null && format
        ? format(safeVal)
        : safeVal;

    return [title, formatted || ""];
};