import { StateModel, StrictState } from "@autocorp/redux";
import { IDealerState, IUserState } from "@store";
import { modelSelectors } from "./selectors";

import type {
    CreditQueryInput,
    AnswerMutation,
    AuthMutation,
} from "@graphql/schema";

type CreditAuth = NonNullable<AuthMutation["creditAuth"]>;
type CreditReportAnswer = NonNullable<AnswerMutation["creditQuery"]>["report"];

export type AdditionalInformation = {
    modelYear?: string;
    makeId?: string;
    modelId?: string;
    vehicleId?: string;
    mileage?: string;
    condition?: string;
    makeName?: string;
    modelName?: string;
    trimName?: string;
    vehicleTitle?: string;
    interestRate?: string;
};

interface IMSAAppState {
    residenceTime: string;
    jobTitle: string;
    incomeTime: string;
    rentOrOwn: string;
    monthlyHomePayment: string;
    interestRate?: string;
    monthlyBudget?: string;
    additionalInformation: AdditionalInformation;

    obtainScoreConsent: boolean;
    dealerCreditConsent: boolean;
    consentCollectCredit: boolean;

    retries: number;

    vehicle: string;
    employer: string;
    position: string;
    incomeAmount: string;
    employmentStatus: string;
    incomeTimeYears: string;
    incomeTimeMonths: string;
    residenceTimeYears: string;
    residenceTimeMonths: string;
}

interface ICreditScoreState {
    query: Omit<CreditAuth, "consumer"> | null;
    answers: CreditQueryInput | null;
    report: CreditReportAnswer;
}

export interface IMSAToolState extends IMSAAppState, ICreditScoreState { }
export interface ICombinedState extends IUserState, IDealerState, IMSAToolState { }

const defaultState: StrictState<IMSAToolState> = {
    residenceTime: "",
    jobTitle: "",
    incomeAmount: "",
    incomeTime: "",
    rentOrOwn: "Rent",
    monthlyHomePayment: "",
    interestRate: "",
    additionalInformation: {},

    query: null,
    answers: null,
    report: undefined,

    obtainScoreConsent: false,
    dealerCreditConsent: true,
    consentCollectCredit: false,

    retries: 0,

    vehicle: "",
    employer: "",
    position: "",
    employmentStatus: "",
    monthlyBudget: "",
    incomeTimeYears: "",
    incomeTimeMonths: "",
    residenceTimeYears: "",
    residenceTimeMonths: "",
};

export const MSAToolModel = new StateModel({
    name: "msaTool",
    state: defaultState,
    selectors: modelSelectors,
});
