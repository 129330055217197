import { AppModal } from "./AppModal";

import type {
    ModalTopLevelComponent,
    AnyModalViewComponent,
    ModalViewComponent,
    ModalViewMembers,
    ModalTopLevelProps,
    HookFunction,
} from "@model/view/modal";

export {
    SidePane,
    Footer,
    DefaultFooter,
    Section,
    Actions,
} from "./Modal";
export type { ModalViewComponent, ModalViewMembers, HookFunction };


export const createModalView = <
    TComponent extends AnyModalViewComponent,
>(
    viewComponent: TComponent,
    containerProps: ModalTopLevelProps,
    {
        showFooter = true,
        ...args
    } = {} as ModalViewMembers<TComponent>,
): ModalTopLevelComponent => {
    const Component: ModalTopLevelComponent = Object.assign<
        ModalViewComponent,
        ModalViewMembers,
        Pick<ModalTopLevelComponent, "Container" | "containerProps">
    >(
        viewComponent as unknown as ModalViewComponent,
        Object.entries(args).reduce((acc, [memberKey, memberValue]) => {
            return Object.assign<ModalViewMembers, ModalViewMembers>(
                acc,
                { [memberKey]: memberValue },
            );
        }, {
            showFooter,
        } as ModalViewMembers),
        {
            Container: AppModal,
            containerProps,
        },
    );

    return Component;
};