import { h, FunctionalComponent, JSX } from "preact";
import clsx from "clsx";
import styles from "./Grid.module.scss";

export interface IGridProps {
    className?: string;
    style?: JSX.CSSProperties;
    columns?: 1 | 2;
}

export const Grid: FunctionalComponent<IGridProps> = ({ children, className, style = {}, columns = 2 }) => (
    <div
        className={clsx(
            styles.grid,
            columns === 1
                ? styles.c1
                : styles.c2,
            className,
        )}
        style={style}
    >
        {children}
    </div>
);