import { h } from "preact";

import { MessagePage } from "@app-modal/Modal/MessagePage";

import { useMSAToolModels } from "@msa-tool/state";
import thumbsup from "@assets/emoji/emoji-thumbs-up.png";

import type { MSAToolViewComponent } from "@msa-tool";
import { getCustomer } from "@msa-tool/config/customers";

const maxRetries = 1;

export const CustomErrorStep: MSAToolViewComponent = ({ goToNext, navigateTo }) => {
    const {
        msaTool: {
            useSelector,
            update,
        },
    } = useMSAToolModels();
    const retries = useSelector("retries");

    const retry = () => {
        update.retries(retries + 1);
        navigateTo("phone");
    };

    const canRetry = retries < maxRetries;
    const config = getCustomer();

    return (
        <MessagePage
            emoji={thumbsup}
            title="Thank you! Your application has been submitted and our approval centre will be in touch shortly."
            messages={[
                "We were unable to retrieve your Equifax bureau, we apologize.",
                "If you have any additional questions, please contact us at:",
                config?.phoneNumber || "",
                config?.email || "",
            ]}
            actions={canRetry ? [
                {
                    onClick: retry,
                    label: (
                        <>
                            {"RETRY CREDIT SCORE"}
                        </>
                    ),
                },
            ] : undefined}
        />
    );
};