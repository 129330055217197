import { h, FunctionalComponent, VNode, Ref } from "preact";
import clsx from "clsx";
import type { FrameHandlerOpts } from "@host/frame";

import styles from "./Modal.module.scss";

import type { IModalViewActions } from "@model/view/modal";
import { Header } from "./Header";

interface IModalProps {
    modalRef?: Ref<HTMLDivElement>;
    embedStyle?: FrameHandlerOpts["embed"];
    viewActions: IModalViewActions;
    closeButton?: boolean;
    backButton?: boolean;
    header?: VNode | FunctionalComponent;
    footer?: false | VNode | FunctionalComponent;
    sidePane?: VNode | FunctionalComponent;
    actions?: VNode;
    error?: VNode;
    className?: string;
    capWidth?: boolean;
}

const RenderProp: FunctionalComponent<{
    Component?: false | VNode | FunctionalComponent;
}> = ({ Component }) => (
    Component && (
        typeof Component === "object" ? (
            Component
        ) : (
            <Component />
        )
    ) || null
);

export const Modal: FunctionalComponent<IModalProps> = ({
    modalRef,
    embedStyle = false,
    closeButton = true,
    backButton = false,
    capWidth = false,
    viewActions,
    header,
    footer,
    sidePane,
    error = null,
    children,
    className,
}) => {
    return (
        <div className={clsx(
            embedStyle === "static" && styles.formBuffer,
        )}>
            <div
                id="modal-root-container"
                ref={modalRef}
                className={clsx(
                    className,
                    styles.modal,
                    embedStyle && styles.embedded,
                    (embedStyle === "dynamic") && styles.nocontainerEmbed,
                    !!sidePane && "with-side-pane",
                    capWidth && "cap-width",
                )}
            >
                <div className={styles.modalHeader}>
                    <RenderProp Component={header} />
                    <Header
                        goToPrev={viewActions.goToPrev}
                        onClose={viewActions.closeModal}
                        closeButton={closeButton}
                        backButton={backButton}
                    />
                </div>
                {sidePane && (
                    <div className={styles.sidePane}>
                        <RenderProp Component={sidePane} />
                    </div>
                )}
                <div className={styles.modalContent}>
                    {children}
                </div>
                <div className={styles.modalFooter}>
                    <RenderProp Component={footer} />
                </div>
                {error}
            </div>
        </div>
    );
};