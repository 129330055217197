import { h } from "preact";
import { useMemo } from "preact/hooks";
import { BaseRetention, IBaseRetentionProps } from "../base";

import type { CreditToolViewComponent } from "@model/view/credit-tool";
import type { RetentionView } from "../base/types";

type PageRetentionProps = Record<string, IBaseRetentionProps>;

export interface IMSAToolRetentionProps {
    page: keyof PageRetentionProps;
}

export const MSAToolRetention: RetentionView<
    any,
    CreditToolViewComponent
> = (props) => {
    const retentionProps = useMemo(() => {
        return {
            title: "Are you sure you want to exit? Your information will be lost.",
            subtitles: [
                "Click 'NO' to stay and see your true Equifax credit score for free.",
            ],
            buttonsProps: [
                {
                    label: "No, I want to receive my credit score",
                    onClick: () => null,
                },
            ],
        };
    },[]);

    return (
        <BaseRetention
            {...props}
            {...retentionProps}
        />
    );
};