import { attachModel, getCombinedModels, hydrateModel } from "@store";
import { CreditToolModel, ICreditToolState, ICombinedState } from "./model";
import { rootSelectors } from "./selectors/";
import { combinedRootActions } from "./actions";

export const creditToolModels = {
    CreditToolModel,
};

export const combinedModels = getCombinedModels(creditToolModels);
export type CombinedModels = typeof combinedModels;

const store = attachModel(creditToolModels, {
    selectors: rootSelectors,
    asyncHandlers: combinedRootActions,
    events: {
        postInit: async (instance) => {
            // instance.model.creditTool.update.report(
            //     require("../__fixtures/getAnswer1.json").creditQuery.report,
            // );
            await hydrateModel(CreditToolModel, instance);
        },
    },
});

export type { IUserState } from "@store";
export type { ICreditToolState, ICombinedState };

export const useCreditToolStore = () => store.useStore();
export const useCreditToolModels = () => store.useModel();