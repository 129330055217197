import { createRouteMap } from "@model/routes";
import { steps } from "./config";

export const routeMap = createRouteMap({
    base: "/msa-tool",
    errorPage: "error",
    routes: {
        ...steps,
        "eid": { next: "score", back: false, prefetch: ["error"] },
        "score": {},
        "score-details": { back: true },
        "error": { next: "vehicle", back: false },
        "equifax-down": { next: "vehicle", back: false },
        "eid-failure": { next: "vehicle", back: false },
        "fraud-check": { next: "vehicle", back: false },
        "thank-you": {},
    },
});

export type { MSAToolRouteMapping } from "@model/routes";