
import { mapStateToAuth, mapStateToMSAApp, mapStateToPartialLead } from "../mapping";
import { createRootSelectors } from "../creators";

const stateSelectors = createRootSelectors({
    combinedState(_: null, models) {
        return {
            ...models.dealer.state,
            ...models.msaTool.state,
            ...models.user.state,
        };
    },
});

const calculatedSelectors = createRootSelectors({
    getAuthPayload(_: null, models) {
        const state = stateSelectors.combinedState(null, models);
        return mapStateToAuth(state);
    },
    getMSAAppPayload(_: null, models) {
        const state = stateSelectors.combinedState(null, models);
        return mapStateToMSAApp(state);
    },
    getPartialPayload(_: null, models) {
        const state = stateSelectors.combinedState(null, models);
        return mapStateToPartialLead(state);
    },
});

export const rootSelectors = {
    ...stateSelectors,
    ...calculatedSelectors,
};
