import { VNode, FunctionalComponent } from "preact";

import type { LaunchContext } from "@autocorp/ava";
import type { ErrorHandler, CustomError } from "@autocorp/ava/esm/util/error-handler";
import type { RouteMapping, AnyRouteMapping } from "@model/routes";
import type { WidgetOverrides } from "@model/widget/overrides";
import type { INavigateToOptions } from "@hooks/use-router";
import type { NextViewComponent, TopLevelComponent, TopLevelContainer } from "../internal";

interface IShowErrorOptions {
    extra?: Record<string, unknown>;
    service?: string;
}
export interface INavigateOptions extends INavigateToOptions {
    blockBack?: boolean;
}

type UnwrapArray<T> = T extends Array<infer U> ? U : T;

export const hookActions = {
    block: Symbol("block_hook"),
};
export type HookActions = typeof hookActions;
export type HookFunction = (actions: HookActions) => HookActions[keyof HookActions] | void;

export type HookSet = {
    close?: HookFunction[];
}
export type HooksOf<K extends keyof HookSet> = HookSet[K];
export type HookTypeOf<K extends keyof HookSet> = UnwrapArray<HookSet[K]>;

export interface IModalViewActions<TRoutes = string> {
    goToPrev: (options?: INavigateOptions) => void;
    goToNext: (options?: INavigateOptions) => void;
    navigateTo: (subRoute: TRoutes, options?: INavigateOptions) => void;
    isLoading: (status: boolean) => void;
    showError: (err?: Error | CustomError | string, options?: IShowErrorOptions) => void;
    submitFeedback: (feedback: string) => void;
    closeModal: () => void;
    useModalHook: <T extends keyof HookSet>(hook: T, listener: HookTypeOf<T>) => void;
}

export interface IModalViewDefaultProps<
    TRoutes extends AnyRouteMapping,
    TContext extends LaunchContext
> extends IModalViewActions<keyof TRoutes["routes"]> {
    errorHandler: ErrorHandler;
    embedded: boolean;
    context?: TContext;
    overrides?: { nextStepsBody: string };
}

export type ModalViewComponent<
    TRouteMapping extends AnyRouteMapping = AnyRouteMapping,
    TContext extends LaunchContext = LaunchContext,
    TInitialProps = Record<string, unknown>,
    TProps = Record<string, unknown>,
> = NextViewComponent<
    TInitialProps,
    IModalViewDefaultProps<
        TRouteMapping,
        TContext
    > & TProps
>;

export type AnyModalViewComponent = ModalViewComponent<
    AnyRouteMapping,
    any,
    any,
    any
>;

export type ModalViewMembers<C = AnyModalViewComponent> = Partial<{
    capWidth: boolean;
    showFooter: boolean;
    contentClass: string;
    sidePane: VNode | FunctionalComponent;
    header: VNode | FunctionalComponent;
    footer: VNode | C;
}>;

export type ModalView<
    TInitialProps = Record<string, unknown>,
    TProps = Record<string, unknown>,
> = ModalViewComponent<
    AnyRouteMapping,
    TInitialProps,
    TProps
> & ModalViewMembers;

export type ModalTopLevelProps = {
    routeMap: RouteMapping<any, any>;
}

export type ModalTopLevelComponent = TopLevelComponent<ModalView, any, ModalTopLevelProps>;
export type ModalTopLevelContainer = TopLevelContainer<ModalTopLevelComponent>;
