import { h, FunctionalComponent } from "preact";
import { useTranslation } from "react-i18next";
import { useCreditToolModels } from "@credit-tool/state";
import { Text } from "@components/Text/Text";

import styles from "./Header.module.scss";
import CloseIcon from "@assets/close-icon.svg";
import BackIcon from "@assets/back-icon.svg";

export interface IHeaderProps {
    backButton: boolean;
    closeButton: boolean;
    goToPrev: () => void;
    onClose?: () => void;
    // progress?: number;
}

const LeftPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelLeft}>{children}</div>
);
const RightPanel: FunctionalComponent = ({ children }) => (
    <div className={styles.headerPanelRight}>{children}</div>
);

export const Header: FunctionalComponent<IHeaderProps> = ({
    children,
    backButton,
    closeButton,
    onClose,
    goToPrev,
}) => {
    const { t, i18n } = useTranslation();
    const { products: { state: productsState } } = useCreditToolModels();

    return (
        <div className={styles.header}>
            <LeftPanel>
                {backButton && (
                    <div className={styles.backIconContainer} onClick={goToPrev}>
                        <BackIcon />
                        <Text block className={styles.backIconText}>{t("back")}</Text>
                    </div>
                )}
            </LeftPanel>
            <RightPanel>
                {productsState.creditTool && (
                    <select
                        value={i18n.language}
                        onChange={(e) => i18n.changeLanguage(e.currentTarget.value)}
                        className={styles.langDropdown}
                        style={{ marginRight: closeButton ? "16px" : "0" }}
                    >
                        <option value="en">🇨🇦 - English</option>
                        <option value="fr">🇨🇦 - French</option>
                    </select>
                )}
                {closeButton && (
                    <div className={styles.closeIcon} onClick={onClose}>
                        <CloseIcon />
                    </div>
                )}
            </RightPanel>
            {children}
        </div>
    );
};