import { h, FunctionalComponent } from "preact";
import clsx from "clsx";
import styles from "./GridItem.module.scss";

export interface IGridItemProps {
    fullWidth?: boolean;
    cols?: ColSpans[];
    className?: string;
}

type ColSpans = keyof typeof styles;

export const GridItem: FunctionalComponent<IGridItemProps> = ({ children, fullWidth, cols, className }) => {

    if (cols && fullWidth) {
        throw new Error("[GridItem] You cannot define `cols` and `fullWidth` at the same time!");
    }

    const classNames = fullWidth
        ? styles.fullWidth
        : clsx(...(cols as string[]));

    return (
        <div className={clsx(classNames, className)}>{children}</div>
    );
};