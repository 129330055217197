import { frameHandler } from "@host";
import { MSAToolViewComponent } from "@model/view/msa-tool";
import { CustomErrorStep } from "../../clc-error/Error";
import { CustomScore } from "../../clc-score/Score";

// const CLC_DOMAINS = ["https://refi.carloanscanada.com", "https://equifax.carloanscanada.com"];
export const isCLC = frameHandler.domain?.includes(".carloanscanada.com") || false;

interface Customer {
    id: string;
    phoneNumber: string;
    email: string;
    ScorePage?: MSAToolViewComponent;
    ErrorPage?: MSAToolViewComponent;
}

export const customers = (): Customer[] => [
    {
        id: "clc",
        phoneNumber: "+1 888 536-4210",
        email: "info@carloanscanada.com",
        ScorePage: CustomScore,
        ErrorPage: CustomErrorStep,
    },
];

export const getCustomer = (): Maybe<Customer> => {
    if (isCLC) {
        return customers()[0] as Customer;
    }

    return null;
};