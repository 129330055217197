import { useMemo, useCallback, useState, useRef, useEffect, Ref } from "preact/hooks";
import { RouteMapping, AnyRouteMapping } from "@model/routes";
import { useRootModels, useRootStore } from "@store";
import { events, frameHandler, errorHandler } from "@host";
import { rootApiClient } from "@api/api-client";

import { WidgetOverrides } from "@model/widget/overrides";
import { hookActions } from "@model/view/modal";
import type {
    IModalViewActions,
    INavigateOptions,
    HookSet,
    HooksOf,
    HookTypeOf,
} from "@model/view/modal";
import type { ModalRouter } from "@hooks/use-router";
import { triggerEvent } from "@host";

const getModalHookManager = (set: Ref<HookSet>): IModalViewActions["useModalHook"] => (
    function useModalHook<K extends keyof HookSet>(hook: K, listener: HookTypeOf<K>) {

        const getHooks = useCallback((hook: K) => {
            return set.current[hook] = (
                set.current[hook] || []
            ) as HooksOf<K>;
        }, []);

        const addHook = useCallback((hook: K, listener: HookTypeOf<K>) => {
            const hooks = getHooks(hook);
            hooks!.push(listener!);
        }, [getHooks]);

        const removeHook = useCallback((hook: K, listener: HookTypeOf<K>) => {
            const hooks = getHooks(hook);
            const maxLen = (hooks?.length || 1) - 1;
            hooks?.forEach((_, pos, arr) => {
                const idx = maxLen - pos;
                const hook = arr[idx];
                if (hook === listener) {
                    hooks?.splice(idx, 1);
                }
            }, hooks);
        }, [getHooks]);


        useEffect(() => {
            const curListener = listener;
            addHook(hook, curListener);
            return () => {
                const hooks = set.current[hook];
                if (!hooks) return;
                removeHook(hook, curListener);
            };
        }, [hook, listener, addHook, removeHook]);
    }
);

type CriticalError = { message?: string, service?: string } | null;

export const useModalActions = <M extends RouteMapping<any>>(
    router: ModalRouter,
    routeMap: M,
    modalRef: Ref<HTMLElement | null>,
) => {
    const store = useRootStore();
    const { core: { dispatchAsync: { updateProductFeedback } } } = useRootModels();
    const [loading, setLoading] = useState(false);
    const [blockBack, setBackBlock] = useState(false);
    const [criticalError, setCriticalError] = useState<CriticalError>(null);
    const curCriticalError = useRef<CriticalError>();
    curCriticalError.current = criticalError;

    const hookSet = useRef<HookSet>({});
    const [useModalHook] = useState(() => (
        getModalHookManager(hookSet)
    ));
    const getHooks = useCallback((hook: keyof HookSet) => (
        hookSet.current[hook] || []
    ), []);
    const hookBlocks = useCallback((hook: keyof HookSet) => {
        const hooks = getHooks(hook);
        return !hooks.every((hook) => (
            hook(hookActions) !== hookActions.block
        ));
    }, [getHooks]);

    const scrollToTop = useCallback(() => {
        frameHandler.scrollTo(modalRef.current);
    }, [modalRef]);

    const doneLoading = useCallback(() => {
        scrollToTop();
        setLoading(false);
    }, [scrollToTop]);

    const processNavigateOptions = useCallback((
        {
            blockBack = false,
        } = {} as INavigateOptions,
    ) => {
        setBackBlock(blockBack);
    }, []);

    const sendLeadNotification = useCallback(async () => {
        const { leadId } = store.model.user.get();
        if (!leadId) return;

        try {
            return await rootApiClient.sendNotification({ leadId });
        } catch (err) {
            new errorHandler.ExtendError(err, { leadId }).logError();
            return;
        }
    }, [store]);

    const viewActions = useMemo<IModalViewActions>(() => ({
        useModalHook,
        goToPrev: (options) => (
            processNavigateOptions(options),
            router.back(doneLoading)
        ),
        goToNext: (options) => (
            processNavigateOptions(options),
            router.next(doneLoading)
        ),
        navigateTo: (route: keyof M["routes"], options = {}) => (
            processNavigateOptions(options),
            router.navigateTo(route as string, {
                ...options,
                done: () => {
                    doneLoading();
                    if (options.done) options.done();
                },
            })
        ),

        isLoading: (state = false) => {
            scrollToTop();
            setLoading(state);
        },
        showError: (err, { extra, service } = {}) => {
            if (err) {
                errorHandler.logError(err, extra);
                if (errorHandler.isCritical(err)) {
                    const message = errorHandler.getErrorString(err, false);
                    setLoading(false);
                    triggerEvent({
                        event: "criticalError",
                        context: { message, service },
                    }, true);
                    return setCriticalError({ message, service });
                }
            }
            if (routeMap.errorPage) {
                router.navigateTo(routeMap.errorPage, {
                    track: true,
                    done: doneLoading,
                });
            } else {
                const message = "Internal Error: An unhandled error occurred!";
                setLoading(false);
                triggerEvent({
                    event: "criticalError",
                    context: { message, service },
                }, true);
                setCriticalError({ message, service });
            }
        },
        submitFeedback: (feedback: string) => {
            const { leadId } = store.model.user.get();
            const { widget } = store.model.dealer.get();
            const companyId = widget!.company.id;
            updateProductFeedback({ leadId, companyId, feedback });
        },
        closeModal: () => {
            if (!curCriticalError.current && hookBlocks("close")) return;

            events.appClosed();
            if (!MOCK && !TEST) sendLeadNotification();

            const doReset = () => {
                store.reset();
                setCriticalError(null);
                router.backToLaunch(doneLoading);
            };

            // Fix render flicker when closing modal
            setTimeout(doReset, 50);
        },
    }), [
        useModalHook,
        hookBlocks,
        doneLoading,
        processNavigateOptions,
        routeMap.errorPage,
        router,
        scrollToTop,
        sendLeadNotification,
        updateProductFeedback,
        store,
    ]);

    return {
        viewActions,
        loading,
        blockBack,
        criticalError,
    };
};