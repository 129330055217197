import { useEffect, useMemo, useCallback } from "preact/hooks";
import { useRouter, INavigateToOptions } from "./use-router";
import { initialData, query, frameHandler, errorHandler } from "@host";
import { routeMap as creditToolRouteMap } from "@credit-tool/routes";
import { routeMap as tradeToolRouteMap } from "@trade-tool/routes";
import { routeMap as lenderToolRouteMap } from "@lender-tool/routes";
import { routeMap as msaToolRouteMap } from "@msa-tool/routes";
import { routeMap as driveToolRouteMap } from "@drive-tool/routes";

import { getMembersWithProduct, getMembersWithProducts } from "@prod-dist/utils";

import { useRootModels } from "@store";

import { RouteMapping, WithPrefix, createRouteMap } from "@model/routes";
import { GroupDistType } from "@graphql/schema";

import type { Product, ProductPaths } from "@autocorp/ava";
import type { ProductContext } from "@shared/product-context";
import type { Company } from "@model/company";
import { AllProducts } from "@model/als";

type ProductRouteMapping = RouteMapping<"/", (
    | "/prod-dist/user"
    | "/prod-dist/unavailable"
    | WithPrefix<"/", ProductPaths>
)>;

export function createProductMap(
    ...productRouteMaps: RouteMapping<any, any>[]
): ProductRouteMapping {
    return createRouteMap({
        base: "" as "/",
        routes: productRouteMaps.reduce((acc, { base, start }) => (
            Object.assign(acc, {
                [base.replace(/^\/+/, "")]: {
                    target: `${base}/${start}`,
                },
            })
        ), {
            "prod-dist/user": {},
            "prod-dist/unavailable": {},
        } as ProductRouteMapping["routes"]),
    });
}

export const baseRouteMap = createProductMap(
    creditToolRouteMap,
    tradeToolRouteMap,
    lenderToolRouteMap,
    msaToolRouteMap,
    driveToolRouteMap,
);

type ProdRoutes = keyof (typeof baseRouteMap)["routes"];

const productMap: Record<Product, ProductPaths> = {
    creditTool: "credit-tool",
    tradeTool: "trade-tool",
    lenderTool: "lender-tool",
    msaTool: "msa-tool",
    driveTool: "drive-tool",
};

let prefetched = false;
let curProduct: Product;
let productContext: ProductContext;
const reset = (() => {
    const doReset = () => {
        curProduct = "" as Product;
        productContext = {} as ProductContext;
    };
    return (doReset(), doReset);
})();

export const useProductRouter = (launch?: boolean) => {
    if (launch) reset();

    const baseRouter = useRouter(baseRouteMap as RouteMapping<any, any>, {
        prefetchAll: !prefetched,
    });
    const {
        dealer: {
            get: getDealerState,
        },
        user: {
            get: getUserState,
        },
        products: {
            update: updateProduct,
        },
    } = useRootModels();

    useEffect(() => {
        prefetched = true;
    }, []);

    const hasProduct = useCallback((product: Product, companies?: (Company | undefined)[]) => {
        const userState = getUserState();
        const widgetState = getDealerState().widget;

        const selectedCompanies = companies || [userState.distCompany || widgetState?.company];

        // If product === "msaTool", return "creditTool" for now since they are the same product
        const p = (["msaTool", "creditTool"].includes(product) ? ["creditTool", "creditLiteTool"] : [product]) as AllProducts[];

        const filtered = getMembersWithProducts(
            selectedCompanies,
            p,
        );

        return !!filtered && filtered.length > 0;
    }, [getDealerState, getUserState]);

    const navigateTo = useCallback(<P extends Product>(
        to: P,
        context?: ProductContext[P],
        options?: INavigateToOptions,
    ) => {
        if (!to) {
            errorHandler.logError(
                new errorHandler.CriticalError("Internal Error! Invalid product input", {
                    to,
                    options,
                    initialData,
                    query,
                }),
            );
            to = "!!INTERNAL ERROR!!" as P;
        }

        const userState = getUserState();
        const widgetState = getDealerState().widget;
        const distType = widgetState?.group.distributionType;
        const unavailable: ProdRoutes = "prod-dist/unavailable";

        let productAvailable = hasProduct(
            to,
            [userState.distCompany || widgetState?.company],
        );
        let target: ProdRoutes = productMap[to];

        if (launch) {
            switch (distType) {
                case GroupDistType.User: {
                    target = "prod-dist/user";
                    productAvailable = hasProduct(
                        to,
                        widgetState?.memberCompanies,
                    );
                    break;
                }
            }
        }

        if (!productAvailable || !target) {
            let message = "Invalid product selected!";
            if (!productAvailable && target) message = "Selected product is unavailable!";
            console.error(
                message,
                "Product desired:", `<${to}>`,
                "navigation target:", `<${target}>`,
            );
            target = unavailable;
        }

        if (context) {
            productContext[to] = context;
        }

        curProduct = to;

        baseRouter.navigateTo(target, {
            ...options || {},
            context: {
                ...options?.context || {},
                product: to,
            },
            query: {
                product: to,
                ...options?.query || {},
            },
        });
        updateProduct[to](true);

        frameHandler.product = to;
    }, [getUserState, getDealerState, hasProduct, launch, baseRouter, updateProduct]);

    const router = useMemo(() => ({
        ...baseRouter,
        hasProduct,
        navigateTo,
        context: productContext[curProduct],
    }), [baseRouter, hasProduct, navigateTo]);

    return router;
};
