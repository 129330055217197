import { h, VNode } from "preact";
import { useCallback, useRef, useMemo } from "preact/hooks";
import debounce from "lodash/debounce";

import { Overlay } from "@components/Overlay";
import { Loading } from "@components/Loading";
import {
    Modal,
    Content,
    CriticalError,
    DefaultFooter,
} from "./Modal";
import { useModalActions } from "./actions";
import styles from "./AppModal.module.scss";

import { frameHandler, errorHandler } from "@host";
import { useFrameListener } from "@hooks/use-frame-listener";
import { useResizeListener } from "@hooks/use-resize-listener";
import { useRouter } from "@hooks/use-router";
import { useProductRouter } from "@hooks/use-product-router";

import type {
    ModalTopLevelContainer,
    IModalViewDefaultProps,
} from "@model/view/modal";
import type { AnyRouteMapping } from "@model/routes";
import type { LaunchContext } from "@autocorp/ava";

const resizeListener = debounce((width: number, height: number) => {
    if (frameHandler.embed) {
        // console.log("resizeListener:", "emit", { width, height });
        frameHandler.send("resize", {
            width,
            height,
        });
    }
}, 10);

function isVNode(input?: any): input is VNode {
    return input && typeof input === "object";
}

export const AppModal: ModalTopLevelContainer = ({
    Component,
    pageProps,
}) => {
    const overlayRef = useResizeListener<HTMLDivElement>(resizeListener, () => (
        frameHandler.embed === "dynamic"
    ));
    const modalRef = useRef<HTMLDivElement | null>(null);

    const { routeMap } = Component.containerProps;
    const router = useRouter(routeMap);
    const productRouter = useProductRouter();

    const reportSizeListener = useCallback(() => {
        const el = overlayRef.current;
        if (!el) return;
        resizeListener(el.offsetWidth, el.offsetHeight);
    }, [overlayRef]);
    useFrameListener(
        "reportSize",
        reportSizeListener,
        () => frameHandler.embed === "dynamic",
    );

    const {
        viewActions,
        loading,
        blockBack,
        criticalError,
    } = useModalActions(router, routeMap, modalRef);

    const viewComponentProps = Object.assign<
        Record<string, unknown>,
        IModalViewDefaultProps<AnyRouteMapping, LaunchContext>
    >(
        pageProps,
        {
            ...viewActions,
            errorHandler,
            embedded: !!frameHandler.embed,
            context: productRouter.context,
        },
    );

    const hasBack = useMemo(() => router.hasBack, [router]);
    const FooterComponent = Component.footer;
    const showFooter = Component.showFooter ?? !!FooterComponent;

    return (
        <Overlay
            className={styles.app}
            // transparent={!!frameHandler.embed}
            overlayRef={overlayRef}
        >
            <Modal
                modalRef={modalRef}
                embedStyle={frameHandler.embed}
                viewActions={viewActions}
                closeButton={!frameHandler.embed}
                backButton={!blockBack && !loading && hasBack}
                header={Component.header}
                footer={showFooter && !!FooterComponent && (
                    isVNode(FooterComponent)
                        ? FooterComponent
                        : <FooterComponent {...viewComponentProps} />
                ) || (
                        <DefaultFooter
                            {...viewComponentProps}
                            learnMore={false}
                        />
                    )}
                sidePane={Component.sidePane}
                className={styles.modal}
                error={(criticalError
                    ? (
                        <CriticalError
                            message={criticalError.message}
                            service={criticalError.service}
                            close={viewActions.closeModal}
                            parentContainer={modalRef}
                        />
                    )
                    : undefined
                )}
                capWidth={Component.capWidth}
            >
                <Content className={Component.contentClass}>
                    <Component {...viewComponentProps} />
                </Content>
                {loading && <Loading />}
            </Modal>
        </Overlay>
    );
};