import { BREEZE_DOMAINS } from "../steps";
import CarImg from "@assets/car.png";
import TruckImg from "@assets/truck.png";
import SuvImg from "@assets/suv.png";
import MinivanImg from "@assets/minivan.png";
import SledImg from "@assets/sled.png";
import GolfImg from "@assets/golf.png";
import RvImg from "@assets/rv.png";
import AtvImg from "@assets/atv.png";
import MotorcycleImg from "@assets/motorcycle.png";
import PowersportsImg from "@assets/powersports.png";
import OtherVehicleImg from "@assets/other.png";
import ClassA from "@assets/rv-expanded-class-a.png";
import ClassB from "@assets/rv-expanded-class-b.png";
import ClassC from "@assets/rv-expanded-class-c.png";
import FifthWheel from "@assets/rv-expanded-fifth-wheel.png";
import MicroTrailer from "@assets/rv-expanded-micro-trailer.png";
import TentTrailer from "@assets/rv-expanded-tent-trailer.png";
import TravelTrailer from "@assets/rv-expanded-travel-trailer.png";

const automotive = {
    question: "What type of vehicle are you looking for?",
    answers: BREEZE_DOMAINS
        ? [
            {
                key: "atv",
                value: "ATV",
            },
            {
                key: "watercraft",
                value: "Watercraft",
            },
            {
                key: "snowmobile",
                value: "Snowmobile",
            },
            {
                key: "trailer",
                value: "Trailer",
            },
        ] : [
            {
                key: "car",
                value: "Car",
                image: CarImg,
            },
            {
                key: "truck",
                value: "Truck",
                image: TruckImg,
            },
            {
                key: "suv",
                value: "SUV",
                image: SuvImg,
            },
            {
                key: "mini-van",
                value: "Mini Van",
                image: MinivanImg,
            },
        ],
};

export const getDynamicConfig = (type: string) => {
    if (!type?.toLowerCase) {
        return automotive;
    }
    switch (type.toLowerCase()) {
        case "powersports":
            return {
                question: "What type of product are you looking for?",
                answers: [
                    {
                        key: "sled",
                        value: "Sled",
                        image: SledImg,
                    },
                    {
                        key: "atv",
                        value: "ATV / Side by Side",
                        image: AtvImg,
                    },
                    {
                        key: "motorcycle",
                        value: "Motorcycle",
                        image: MotorcycleImg,
                    },
                    {
                        key: "other",
                        value: "Other",
                        image: OtherVehicleImg,
                    },
                ],
            };
        case "powersports-2":
            return {
                question: "What type of product are you looking for?",
                answers: [
                    {
                        key: "powersports",
                        value: "Powersports",
                        image: PowersportsImg,
                    },
                    {
                        key: "rv/trailer",
                        value: "RV / Trailer",
                        image: RvImg,
                    },
                    {
                        key: "marine",
                        value: "Marine",
                        image: OtherVehicleImg,
                    },
                    {
                        key: "other",
                        value: "Other",
                        image: GolfImg,
                    },
                ],
            };
        case "rv":
            return {
                question: "What type of product are you looking for?",
                answers: [
                    {
                        key: "rv",
                        value: "RV",
                    },
                    {
                        key: "5th-wheel",
                        value: "5th Wheel",
                    },
                    {
                        key: "trailer",
                        value: "Trailer",
                    },
                    {
                        key: "other",
                        value: "Other",
                    },
                ],
            };
        case "rv-expanded":
            return {
                question: "What type of product are you looking for?",
                answers: [
                    {
                        key: "class-a",
                        value: "Class A",
                        image: ClassA,
                    },
                    {
                        key: "class-b",
                        value: "Class B",
                        image: ClassB,
                    },
                    {
                        key: "class-c",
                        value: "Class C",
                        image: ClassC,
                    },
                    {
                        key: "fifth-wheel",
                        value: "Fifth Wheel",
                        image: FifthWheel,
                    },
                    {
                        key: "travel-trailer",
                        value: "Travel Trailer",
                        image: TravelTrailer,
                    },
                    {
                        key: "micro-trailer",
                        value: "Micro Trailer",
                        image: MicroTrailer,
                    },
                    {
                        key: "tent-trailer",
                        value: "Tent Trailer",
                        image: TentTrailer,
                    },
                ],
            };
        case "default":
        default:
            return automotive;
    }
};