import { h, Fragment, VNode, FunctionalComponent } from "preact";
import clsx from "clsx";

import { Section, Actions } from "@app-modal";
import { Title } from "@components/Text";
import { ActionsProps } from "../Actions";

import styles from "./MessagePage.module.scss";

interface IMessagePageProps {
    emoji?: string;
    title?: VNode | string | (VNode | string)[];
    messages?: (VNode | string)[];
    bulletHeader?: string;
    bulletList?: (VNode | string)[];
    actions?: [ActionsProps<any>, ...(VNode | boolean | null)[]];
}

export const MessagePage: FunctionalComponent<IMessagePageProps> = ({
    emoji,
    title,
    messages = [],
    bulletHeader,
    bulletList = [],
    actions = [],
}) => {
    return (
        <Fragment>
            {emoji && (
                <Section className={styles.container}>
                    <img src={emoji} className={styles.emoji} />
                </Section>
            )}
            {title && (title = Array.isArray(title) ? title : [title], (
                <Section top={16} className={styles.container}>
                    {title.map((titleLine) => (
                        typeof titleLine === "string" ? (
                            <Title big block>
                                {titleLine}
                            </Title>
                        ) : (
                            {titleLine}
                        )
                    ))}
                </Section>
            ))}
            {messages.map((message, i) => (
                <Section key={`message-${i}`} top={20} className={styles.container}>
                    {typeof message === "string" ? (
                        <div className={styles.message}>
                            {message}
                        </div>
                    ) : (
                        message
                    )}
                </Section>
            ))}
            {bulletHeader && (
                <Section top={32} className={styles.container}>
                    {bulletHeader && (
                        <div className={styles.bulletHeader}>
                            {bulletHeader}
                        </div>
                    )}
                </Section>
            )}
            {bulletList && (
                <Section top={8} className={styles.container}>
                    <ul className={styles.list}>
                        {bulletList.map((message, i) => {
                            if (typeof message === "string") {
                                const msg = message.split("\n")
                                    .map((m, i) => (
                                        i === 0 ? m : <p key={i}>{m}</p>
                                    ));
                                return (
                                    <li key={`bullet-list-item-${i}`} className={styles.listItem}>
                                        {msg}
                                    </li>
                                );
                            }
                            return message;
                        })}
                    </ul>
                </Section>
            )}
            {actions.length > 0 && (
                <Actions
                    {...actions[0]!}
                    containerClass={clsx(styles.actions, actions[0]?.containerClass)}
                    footnote={actions[0]?.footnote || false}
                >
                    {actions.slice(1).filter(Boolean)}
                </Actions>
            )}
        </Fragment>
    );
};