import { h, FunctionalComponent } from "preact";
import { useState, useEffect } from "preact/hooks";

interface IGaugeProps {
    score: number;
    // setHue: (perc: number) => void;
}

const MIN_ANGLE = -3;
const MAX_ANGLE = 183;
const DEGREE_ROTATION = Math.abs(MIN_ANGLE - MAX_ANGLE);
const MIN_SCORE = 300;
const MAX_SCORE = 900;

export const Gauge: FunctionalComponent<IGaugeProps> = ({
    score,
    // setHue
}) => {
    const [curAngle, setAngle] = useState(MIN_ANGLE);

    useEffect(() => {
        const curScore = score;
        const scorePerc = (curScore - MIN_SCORE) / (MAX_SCORE - MIN_SCORE);
        const scoreDeg = MIN_ANGLE + (DEGREE_ROTATION * scorePerc);
        window.requestAnimationFrame(() => {
            setTimeout(() => {
                setAngle(scoreDeg);
                // setHue(scorePerc);
            }, 150);
        });
    }, [score/* , setHue */]);

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width="124.84489"
            height="249.79176"
            viewBox="0 0 124.84489 249.79176"
            fill="none"
            overflow="visible"
        >
            <g
                clipPath="url(#clip0)"
                id="g16"
            >
                <rect
                    width="141"
                    height="284"
                    fill="white"
                    id="rect2"
                />
                <g
                    filter="url(#filter0_d)"
                    id="g6"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M-0.393934 0.563946C20.9133 0.508837 41.8788 5.91768 60.5 16.2738C79.1213 26.6299 94.7763 41.5875 105.97 59.7177C117.163 77.8479 123.521 98.5453 124.437 119.833C125.352 141.121 120.795 162.287 111.199 181.312C101.603 200.336 87.2898 216.582 69.6263 228.499C51.9629 240.415 31.5391 247.604 10.3058 249.378L9.05894 249.482L-0.316361 249.482L-0.316359 189.482L6.43734 189.482C17.0426 188.409 27.224 184.727 36.0703 178.759C45.2297 172.58 52.6518 164.156 57.6277 154.291C62.6035 144.425 64.967 133.449 64.4923 122.411C64.0176 111.372 60.7206 100.639 54.9162 91.2379C49.1118 81.8365 40.9939 74.0803 31.3378 68.7101C21.6818 63.3399 10.8101 60.5352 -0.238777 60.5637L-0.393934 0.563946Z"
                        fill="url(#paint0_linear)"
                        id="path4"
                    />
                </g>
                <path
                    d="m -0.393555,145.54756 c 11.045655,0 19.999955,-8.954 19.999955,-20 0,-11.046 -8.9543,-20 -19.999955,-20 z"
                    fill="#f0f0f0"
                    id="path8"
                />
                <g
                    filter="url(#filter1_d)"
                    id="g14"
                    transform="translate(0,-10.884435)"
                    style={{
                        transformOrigin: "4.5px 136.75px",
                        transition: "all 1s ease-in",
                        transform: `translate(2px,-11px) rotate(${curAngle}deg)`,
                    }}
                >
                    <path
                        d="m 4.50964,35 4.50964,105.225 c 0,0 -1.50321,1.503 -4.50964,1.503 C 1.50321,141.728 0,140.225 0,140.225 Z"
                        fill="#ffffff"
                        id="path10"
                    />
                    <path
                        d="M 0.766917,139.884 4.50964,52.5536 8.25237,139.884 c -0.15648,0.114 -0.38876,0.266 -0.69663,0.42 -0.65969,0.33 -1.67274,0.673 -3.0461,0.673 -1.37336,0 -2.38641,-0.343 -3.0461,-0.673 -0.30787,-0.154 -0.540142,-0.306 -0.696623,-0.42 z"
                        stroke="#ffffff"
                        strokeWidth="1.50321"
                        id="path12"
                    />
                </g>
            </g>
            <defs id="defs58">
                <filter
                    id="filter0_d"
                    x="-149.362"
                    y="-25.7658"
                    width="351.251"
                    height="353.033"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood
                        flood-opacity="0"
                        result="BackgroundImageFix"
                        id="feFlood18"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        id="feColorMatrix20"
                    />
                    <feOffset
                        dy="22.5961"
                        id="feOffset22"
                    />
                    <feGaussianBlur
                        stdDeviation="13.1811"
                        id="feGaussianBlur24"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 0.653083 0 0 0 0 0.245833 0 0 0 0.25 0"
                        id="feColorMatrix26"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                        id="feBlend28"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                        id="feBlend30"
                    />
                </filter>
                <filter
                    id="filter1_d"
                    x="-4"
                    y="30"
                    width="17.0193"
                    height="114.728"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood
                        floodOpacity="0"
                        result="BackgroundImageFix"
                        id="feFlood33"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        id="feColorMatrix35"
                    />
                    <feOffset
                        dy="-1"
                        id="feOffset37"
                    />
                    <feGaussianBlur
                        stdDeviation="2"
                        id="feGaussianBlur39"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
                        id="feColorMatrix41"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow"
                        id="feBlend43"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow"
                        result="shape"
                        id="feBlend45"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear"
                    x1="1.10645"
                    y1="13.4318"
                    x2="-31.7179"
                    y2="236.378"
                    gradientUnits="userSpaceOnUse">
                    <stop
                        stopColor="#FF7171"
                        id="stop48"
                    />
                    <stop
                        offset="0.494792"
                        stopColor="#FFCF71"
                        id="stop50"
                    />
                    <stop
                        offset="1"
                        stopColor="#07CA1A"
                        id="stop52"
                    />
                </linearGradient>
                <clipPath id="clip0">
                    <rect
                        width="141"
                        height="284"
                        fill="white"
                        id="rect55"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};