import { h, FunctionalComponent, Fragment } from "preact";
import { useTranslation } from "react-i18next";

import { Footer } from "../Footer";
import { SubText } from "@components/Text/SubText";
import { Expandable } from "@components/Expandable";
import { Text } from "@components/Text";

import equifaxLogo from "@assets/equifax-logo-189x36.png";

interface IDefaultFooterProps {
    showLogo?: boolean;
    learnMore?: boolean;
}

export const DefaultFooter: FunctionalComponent<IDefaultFooterProps> = ({
    showLogo = true,
    learnMore = true,
    children,
}) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            {showLogo && (
                <Fragment>
                    <Text style={{ fontSize: "12px", marginRight: "12px" }}>
                        {t("credit.footer.poweredBy")}
                    </Text>
                    <img src={equifaxLogo} width={110} style={{ marginBottom: "-5px" }} />
                </Fragment>
            )}
            <Footer>
                {learnMore && (
                    <Expandable
                        title={t("credit.footer.learnMore.title")}
                        expanded
                        content={(
                            <SubText block>
                                {t("credit.footer.learnMore.content")}
                            </SubText>
                        )}
                    />
                )}
                {children}
            </Footer>
        </Fragment>
    );
};