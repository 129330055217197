import { h, FunctionalComponent } from "preact";
import { useEffect, useRef } from "preact/hooks";
import { createPortal } from "preact/compat";
import dynamic from "next/dynamic";
import css from "dom-css";
import clsx from "clsx";

import { addResizeListener } from "@util/resize";
import { debounce } from "@util/debounce";

import { frameHandler } from "@host";

import CloseIcon from "@assets/close-icon.svg";
import styles from "./Overlay.module.scss";

export interface IPortalProps {
    nodeId: string;
    resizeContainer?: boolean;
    noAutoScroll?: boolean;
    placement?: "outer" | "inner" | "content";
    onClose?: () => void;
    children: any;
}

const PortalNoSsr: FunctionalComponent<IPortalProps> = ({
    nodeId,
    resizeContainer = false,
    noAutoScroll = false,
    placement = "inner",
    onClose,
    children,
}) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const container = nodeId && document?.getElementById(nodeId) || null;

    useEffect(() => {
        if (!container) return;

        const origHeight = container.style.minHeight;
        let removeResizeListener: VoidFunction = () => null;

        if (contentRef?.current) {
            let oldWidth = -1;
            const doResize = debounce(() => {
                if (!contentRef?.current) {
                    removeResizeListener();
                    return;
                }

                const newWidth = container.clientWidth;
                if (newWidth === oldWidth) return;
                oldWidth = newWidth;

                const totalHeight = Array.from(contentRef.current.children).reduce(
                    (height, el) => {
                        if (el) {
                            height += el.clientHeight;
                        }
                        return height;
                    },
                    0,
                );

                css(container, {
                    minHeight: totalHeight,
                });
            }, 150);

            doResize();
            removeResizeListener = addResizeListener(container, doResize) || (() => null);
        }

        const close = () => {
            container.classList.remove("portal-open");
            css(container, {
                minHeight: origHeight,
            });
            removeResizeListener();
        };

        switch (placement) {
            case "inner":
            case "outer": {
                container.classList.add("portal-open");
                break;
            }
            default: {
                close();
                break;
            }
        }

        return close;
    }, [container, resizeContainer, placement]);

    const hasScrolled = useRef(false);
    useEffect(() => {
        if (!noAutoScroll && !hasScrolled.current) {
            requestAnimationFrame(() => {
                if (contentRef.current) {
                    frameHandler.scrollTo(contentRef.current);
                }
            });
            hasScrolled.current = true;
        }
    }, [noAutoScroll]);

    return (
        <>
            {container &&
                createPortal(
                    <div
                        className={clsx(
                            styles.overlay,
                            placement === "outer" && styles.outer,
                            placement === "inner" && styles.inner,
                            placement === "content" && styles.content,
                        )}
                        ref={contentRef}
                    >
                        <div className={styles.rightPanel}>
                            {onClose &&
                                <div className={styles.closeIcon} onClick={onClose}>
                                    <CloseIcon />
                                </div>
                            }
                        </div>
                        <div className={styles.content}>
                            {children}
                        </div>
                    </div>,
                    container,
                )}
        </>
    );
};

export const Portal = dynamic(() => Promise.resolve(PortalNoSsr), {
    ssr: false,
});
