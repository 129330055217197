import { gql } from "@api/api-client";

export const SubmitLoanApp = gql`
    mutation submitLoanApp($input: LoanAppInput!) {
        loanApp(input: $input) {
            leadId
            msg
            result
        }
    }
`;

export const SubmitPartialLead = gql`
    mutation submitPartialLead(
        $widgetId: String!,
        $input: LeadInput!,
        $product: ProductType!
    ) {
        submitLead(
            widgetId: $widgetId,
            input: $input,
            productType: $product
        ) {
            leadId
            success
        }
    }
`;