import { StateModel, StrictState } from "@autocorp/redux";
import { IDealerState, IUserState } from "@store";
import { modelSelectors } from "./selectors";

import type {
    CreditQueryInput,
    AnswerMutation,
    AuthMutation,
    FetchNeighborhoodViewMutation,
} from "@graphql/schema";

type CreditAuth = NonNullable<AuthMutation["creditAuth"]>;
type CreditReportAnswer = NonNullable<AnswerMutation["creditQuery"]>["report"];

export type AdditionalInformation = {
    stockId?: string;
};

interface ICreditAppState {
    residenceTime: string;
    jobTitle: string;
    incomeAmount: string;
    employer: string;
    incomeTime: string;
    rentOrOwn: string;
    monthlyHomePayment: string;

    obtainScoreConsent: boolean;
    dealerCreditConsent: boolean;
    consentCollectCredit: boolean;
    additionalInformation: AdditionalInformation;

    retries: number;
}

interface ICreditScoreState {
    postalScore: FetchNeighborhoodViewMutation["fetchNeighborhoodView"] | null;
    query: Omit<CreditAuth, "consumer"> | null;
    answers: CreditQueryInput | null;
    report: CreditReportAnswer;
}

export interface ICreditToolState extends ICreditAppState, ICreditScoreState { }
export interface ICombinedState extends IUserState, IDealerState, ICreditToolState { }

const defaultState: StrictState<ICreditToolState> = {
    residenceTime: "",
    jobTitle: "",
    incomeAmount: "",
    employer: "",
    incomeTime: "",
    rentOrOwn: "Rent",
    monthlyHomePayment: "",

    query: null,
    answers: null,
    report: undefined,
    postalScore: null,

    obtainScoreConsent: false,
    dealerCreditConsent: true,
    consentCollectCredit: false,
    additionalInformation: {},

    retries: 0,
};

export const CreditToolModel = new StateModel({
    name: "creditTool",
    state: defaultState,
    selectors: modelSelectors,
});
