import { MSAToolApi } from "@msa-tool/api";
import { triggerEvent, errorHandler } from "@host";
import { getAsyncActionCreator } from "../creators";

import { rootSelectors } from "../selectors";

const asyncCreator = getAsyncActionCreator(rootSelectors);

export const creditAppActions = asyncCreator({
    async submitMSAApp(_: null, models) {
        models.msaTool.update.consentCollectCredit(true);
        const input = rootSelectors.getMSAAppPayload(null, models);

        try {
            await MSAToolApi.submitLoanApp({ input });
            triggerEvent({ event: "CreditAppSubmit" });
        } catch (err) {
            throw new errorHandler.ExtendError(err, { input });
        }
    },
});