import { h, FunctionalComponent } from "preact";
import clsx from "clsx";
import styles from "./Content.module.scss";

interface IContentProps {
    className?: string;
}

export const Content: FunctionalComponent<IContentProps> = ({ className, children }) => {
    return (
        <div className={clsx(styles.content)}>
            <form
                className={clsx(styles.contentForm, className)}
                onSubmit={(e) => e.preventDefault()}
            >
                {children}
            </form>
        </div>
    );
};