import { createRouteMap } from "@model/routes";

export const routeMap = createRouteMap({
    base: "/drive-tool",
    errorPage: "error",
    routes: {
        // "splash": { next: "step-1" },
        "step-1": { next: "step-2" },
        "step-2": { next: "step-3", back: true },
        "step-3": {},
        "error": { next: "step-1", back: false },
        // "thank-you": {},
    },
});

export type { DriveToolRouteMapping } from "@model/routes";