import { h, FunctionalComponent, JSX } from "preact";
import clsx from "clsx";
import styles from "./Link.module.scss";

export interface ILinkProps {
    label: string;
    className?: string;
    onClick: (e: JSX.TargetedEvent<HTMLElement, MouseEvent>) => void;
}

export const Link: FunctionalComponent<ILinkProps> = ({ label, className, onClick }) => (
    <a className={clsx(styles.link, className)} onClick={onClick}>
        {label}
    </a>
);