import { createRouteMap } from "@model/routes";

export const routeMap = createRouteMap({
    base: "/trade-tool",
    routes: {
        "step-1": { next: "cbb-1" },
        "kbb-1": { next: "kbb-2" },
        "kbb-2": { next: "kbb-report" },
        "kbb-report": {},
        "cbb-1": { next: "cbb-2" },
        "cbb-2": { next: "cbb-report" },
        "cbb-report": {},
        "kbb-error": {},
        "trade-error": { next: "step-1" },
    },
});

export type { TradeToolRouteMapping } from "@model/routes";