import { h, FunctionalComponent, VNode } from "preact";
import clsx from "clsx";
import { Title, Text } from "@components/Text";

import styles from "./TitleBlock.module.scss";


export interface ITitleBlockProps {
    title?: string | VNode;
    titleClass?: string;
    className?: string;
    subtitle?: string;
}

export const TitleBlock: FunctionalComponent<ITitleBlockProps> = ({
    title,
    titleClass,
    className,
    children,
    subtitle,
}) => {
    return (
        <div className={clsx(styles.titleBlock, className)}>
            <Title block className={titleClass} big>{title}</Title>
            <Text block>{subtitle}</Text>
            {children}
        </div>
    );
};