import { h, FunctionalComponent, Fragment, VNode } from "preact";
import { useState, useRef, useMemo } from "preact/hooks";
import clsx from "clsx";

import { Title, Text } from "@components/Text";

import {
    creditTradeLineMap,
    getTradeLineField,
} from "../mapping";

import styles from "./TradeLines.module.scss";
import ArrowIcon from "@assets/arrow-icon.svg";

import type {
    CreditTradeLines,
    CreditTradeLineEntry,
    ICreditTradeLineDisplay,
} from "../types";

interface ITradeLinesProps {
    tradeLines: CreditTradeLines;
    /** Indicates the number of entries to display. If undefined, all entries are shown. */
    numEntries?: number;
    /** Determines whether to show the "Expand" icon for Trade Line entries. Defaults to `false`. */
    showExpand?: boolean;
    action?: VNode;
}

interface ITradeLineEntryProps {
    showExpand: boolean;
    setExpanded: (val: boolean) => void;
    tradeLine: ICreditTradeLineEntryExpandable;
}

interface ICreditTradeLineEntryExpandable extends CreditTradeLineEntry {
    expanded: boolean;
}

const getTradelineEntries = (tradeLines: CreditTradeLines, numEntries?: number): ICreditTradeLineEntryExpandable[] => {
    return tradeLines.slice(0, numEntries).map((tradeLine) => ({
        ...tradeLine,
        expanded: false,
    }));
};

const TradeLineEntry: FunctionalComponent<ITradeLineEntryProps> = ({
    tradeLine,
    showExpand,
    setExpanded,
}) => {
    const detailRef = useRef<HTMLDivElement>(null);

    const getDetailHeight = (): string => {
        if (detailRef && tradeLine.expanded) {
            const el = detailRef.current;
            let height = 0;
            el.childNodes.forEach((child) => {
                height += (child as Element).clientHeight;
            });
            return `${height}px`;
        }
        return "0px";
    };

    const getRenderedInfo = (fields: [string, string][], table = true, accent = true): VNode => (
        <div className={styles.entryDetailInfo}>
            {fields.map(([title, value], idx) => (
                <div key={idx} className={styles.entryDetailRow}>
                    <Text block
                        className={clsx(
                            styles.entryDetailInfoValue,
                            styles.entryDetailCell,
                            table && styles.entryDetailTable,
                        )}
                    >
                        {`${title}: `}
                    </Text>
                    <Text block
                        className={clsx(
                            styles.entryDetailInfoValue,
                            styles.entryDetailCell,
                            accent && styles.entryDetailBold,
                            table && styles.entryDetailTable,
                        )}
                    >
                        {value}
                    </Text>
                </div>
            ))}
        </div>
    );

    const summary = useMemo<ICreditTradeLineDisplay>(() => {
        const def = creditTradeLineMap[0];
        const groupName = getTradeLineField(tradeLine, {
            field: def!.group,
            title: "",
        });
        return {
            group: groupName[1],
            fields: getRenderedInfo(def!.fields.map((fieldDef) => getTradeLineField(tradeLine, fieldDef)), false, false),
        };
    }, [tradeLine]);

    const groups = useMemo<ICreditTradeLineDisplay[]>(() => {
        return creditTradeLineMap.slice(1).map(({ group, fields }) => ({
            group,
            fields: getRenderedInfo(fields.map((fieldDef) => getTradeLineField(tradeLine, fieldDef)), true),
        }));
    }, [tradeLine]);

    return (
        <Fragment>
            <div className={styles.entry}>
                <div
                    className={clsx(
                        styles.entrySummary,
                        tradeLine.expanded && styles.entryBorder,
                        showExpand && styles.clickable,
                    )}
                    onClick={() => (
                        showExpand ? setExpanded(!tradeLine.expanded) : null
                    )}
                >
                    <div className={styles.entrySummaryDetail}>
                        <div className={styles.entryBold}>
                            {summary.group}
                        </div>
                        {summary.fields}
                    </div>
                    {showExpand && (
                        <div className={styles.entrySummaryIcon}>
                            <ArrowIcon style={{
                                transition: "all .25s linear",
                                transform: tradeLine.expanded
                                    ? "rotate(-180deg)"
                                    : "rotate(0deg)",
                            }} />
                        </div>
                    )}
                </div>
                <div
                    ref={detailRef}
                    className={styles.entryDetail}
                    style={{
                        height: getDetailHeight(),
                    }}
                >
                    {groups.map(({ group, fields }) => (
                        <div className={styles.entryDetailSegment}>
                            <div className={styles.entryDetailTitle}>
                                {group}
                            </div>
                            {fields}
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export const TradeLines: FunctionalComponent<ITradeLinesProps> = ({
    tradeLines,
    numEntries,
    showExpand = false,
    action,
}) => {

    const [entries, setEntries] = useState(() => getTradelineEntries(tradeLines, numEntries));

    const getExpandedSet = (reqIdx: number) => (val: boolean) => {
        setEntries((curEntries) => {
            return curEntries.map((entry, idx) => {
                if (idx === reqIdx) {
                    entry.expanded = val;
                } else {
                    entry.expanded = false;
                }
                return entry;
            });
        });
    };

    return (
        <Fragment>
            <div className={styles.header}>
                <Title>Trade Line Accounts</Title>
            </div>
            <div className={styles.entries}>
                {entries.map((tradeLine, idx) => (
                    <TradeLineEntry
                        key={idx}
                        tradeLine={tradeLine}
                        setExpanded={getExpandedSet(idx)}
                        showExpand={showExpand}
                    />
                ))}
            </div>
            {tradeLines.length > 0 && action}
        </Fragment>
    );
};