import { CreditQuestion, AnswerMutation } from "@graphql/schema";
import { ChoiceListItem } from "@model/form";

interface ICreditQuestion extends CreditQuestion {
    currentAnswer?: ChoiceListItem;
    error?: string;
}
type RawCreditQuery = Pick<
    NonNullable<AnswerMutation["creditQuery"]>,
    | "equifaxServerError"
    | "equifaxErrorMessages"
    | "fraudCheckFailed"
    | "interactiveQuery"
    | "transactionKey"
>

export interface IEIDQuery {
    queryId: NonNullable<NonNullable<RawCreditQuery["interactiveQuery"]>["queryId"]>;
    equifaxError: RawCreditQuery["equifaxServerError"];
    equifaxErrorMessages: Definitely<RawCreditQuery["equifaxErrorMessages"]>;
    fraudCheckFailed: RawCreditQuery["fraudCheckFailed"];
    transactionKey: NonNullable<RawCreditQuery["transactionKey"]>;
    questions: ICreditQuestion[];
}

export const getEidQuery = (
    creditQuery: RawCreditQuery | null,
): IEIDQuery => {
    const transactionKey = creditQuery?.transactionKey || "";
    const queryId = creditQuery?.interactiveQuery?.queryId || "";

    return {
        transactionKey,
        queryId,
        equifaxError: creditQuery?.equifaxServerError,
        equifaxErrorMessages: (
            creditQuery?.equifaxErrorMessages?.filter(Boolean) || []
        ) as IEIDQuery["equifaxErrorMessages"],
        fraudCheckFailed: creditQuery?.fraudCheckFailed,
        questions: creditQuery?.interactiveQuery?.questions || [],
    };
};