import { h, FunctionalComponent } from "preact";
import clsx from "clsx";
import styles from "./ActionContainer.module.scss";

interface IActionsContainerProps {
    className?: string;
}

export const ActionsContainer: FunctionalComponent<IActionsContainerProps> = ({ children, className }) => (
    <div className={clsx(styles.actions, className)}>{children}</div>
);