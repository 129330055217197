import { gql } from "@api/api-client";

export const GetCreditAuth = gql`
    mutation auth($widgetId: String!, $input: CreditAuthInput!) {
        creditAuth(widgetId: $widgetId, input: $input) {
            transactionKey
            equifaxServerError
            equifaxErrorMessages
            fraudCheckFailed
            interactiveQuery {
                queryId
                questions {
                    questionId
                    questionText
                    answers {
                        answerId
                        value
                    }
                }
            }
            consumer
        }
    }
`;
