import { h, FunctionalComponent, JSX } from "preact";
import clsx from "clsx";
import styles from "./Text.module.scss";

export interface ITextProps {
    bold?: boolean;
    block?: boolean;
    style?: JSX.CSSProperties;
    className?: string;
    onClick?: () => void;
}

const TextElement: FunctionalComponent<ITextProps> = ({
    className,
    style,
    block,
    children,
    onClick,
}) => {
    return (block
        ? <div className={className} style={style} onClick={onClick}>{children}</div>
        : <span className={className} style={style} onClick={onClick}>{children}</span>
    );
};

export const Text: FunctionalComponent<ITextProps> = ({ children, className, bold = false, ...props }) => {
    return (
        <TextElement
            className={clsx(
                bold
                    ? styles.textBold
                    : styles.text,
                className,
            )}
            {...props}
        >
            {children}
        </TextElement>
    );
};