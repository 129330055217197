export type CreditStatusDescriptors = (
    | "Rebuilding"
    | "Fair"
    | "Good"
    | "Excellent"
);

export const statusMap: [number, CreditStatusDescriptors][] = [
    [649, "Rebuilding"],
    [749, "Fair"],
    [799, "Good"],
    [900, "Excellent"],
];

export const getCreditStatus = (score: number): string => {
    const status = statusMap.find(([key]) => score <= key);
    return (status || [])[1] || "";
};

export const maxCreditScore = statusMap.slice(-1)[0]![0];