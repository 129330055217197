import { h, FunctionalComponent, Ref } from "preact";
import clsx from "clsx";
import styles from "./Overlay.module.scss";

interface IOverlayProps {
    // transparent?: boolean;
    fillHeightEmbedded?: boolean;
    overlayRef?: Ref<HTMLDivElement>;
    className?: string;
}

export const Overlay: FunctionalComponent<IOverlayProps> = ({
    // transparent,
    fillHeightEmbedded,
    overlayRef,
    children,
    className,
}) => (
    <div
        ref={overlayRef}
        className={clsx(
            styles.overlay,
            className,
            fillHeightEmbedded && styles.fullHeight,
            // transparent && styles.transparent,
        )}
    >
        {children}
    </div>
);