import isNil from "lodash/isNil";

type TransformerFn<V, R> = (input: V) => R;
type TransformerMap<T, O> = {
    [K in keyof T & keyof O]?: TransformerFn<T[K], O[K]>;
}
type ReturnTypes<T> = {
    [K in keyof T]: ReturnType<Extract<T[K], (...args: any[]) => any>>;
}

type PayloadOf<
    T,
    TAliases extends Record<keyof T, any>
> = {
    [K in keyof T as TAliases[K]]: T[K];
}

type NotUndefined<T, O> = T extends undefined
    ? O
    : T;
type Aliases<T> = {
    [K in keyof T]: K;
}
type AliasesFrom<
    T,
    O
> = {
    [K in keyof T]: K extends keyof O
        ? O[K]
        : K
}

type KeyMap<T, O> = {
    [K in keyof T]?: Exclude<keyof O, keyof T>;
}

export const getStateMapper = <
    TState,
    TNeed,
>() => <
    TStateKeys extends keyof TState,
    TKeyMap extends KeyMap<TState, TNeed> | undefined = (
        undefined
    ),
    TTransformers = TransformerMap<
        Pick<TState, TStateKeys>,
        Pick<TNeed, Extract<keyof TNeed, TStateKeys>>
    >,
    TDefaults extends Record<string, any> = Record<string, any>,
    TPayload = PayloadOf<
        Omit<
            Pick<TState, TStateKeys>,
            keyof TTransformers
        > & ReturnTypes<TTransformers> & TDefaults,
        AliasesFrom<
            Pick<TState, TStateKeys> & ReturnTypes<TTransformers> & TDefaults,
            NotUndefined<
                TKeyMap,
                Aliases<Record<TStateKeys, any>>
            >
        >
    >
>({
    stateKeys,
    keyMap,
    skipFalsy = [],
    transformers = {} as TTransformers,
    defaultValues = {} as TDefaults,
}: {
    stateKeys: TStateKeys[],
    keyMap?: TKeyMap,
    transformers?: TTransformers,
    skipFalsy?: Array<keyof TState>,
    defaultValues?: TDefaults,
}) => (state: TState): TPayload => {
    return stateKeys.reduce((acc, key) => {
        const stateKey = key as TStateKeys;

        const payloadKey = (
            keyMap && key in keyMap
                ? keyMap[key]
                : key
        ) as TStateKeys;

        const transformer = (
            transformers[stateKey as unknown as keyof TTransformers] ||
            transformers[payloadKey as unknown as keyof TTransformers]
        ) as unknown as TransformerFn<any, any>;

        const transformedVal = transformer
            ? transformer(state[stateKey])
            : state[stateKey];

        let payloadVal = transformedVal;
        if (isNil(payloadVal) || isNaN(payloadVal)) {
            if (defaultValues.hasOwnProperty(payloadKey)) {
                payloadVal = defaultValues[payloadKey as keyof TDefaults];
            }
        }

        if (typeof payloadVal?.trim === "function") payloadVal = payloadVal.trim();

        if (skipFalsy.includes(stateKey) && !payloadVal) {
            return acc;
        }

        return Object.assign(acc, {
            [payloadKey]: payloadVal,
        });
    }, {} as TPayload);
};