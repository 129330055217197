import { MAX_QUESTIONS, BREEZE_DOMAINS } from "../steps";
import { getDynamicConfig as getDynamicVehicleConfig } from "./vehicle";

export interface QuestionConfig {
    questionId: string;
    maxQuestions: number,
    fieldName: string;
    question: string;
    answers: {
        key: string;
        value: string;
        image?: string,
    }[];
    dynamicField?: string;
    getDynamicConfig?: (type: string) => {
        question: string;
        answers: {
            key: string;
            value: string;
        }[]
    };
}

export const getQuestion = (qId: string) => questions.find((q) => q.questionId === qId);

export const questions: QuestionConfig[] = [
    {
        questionId: "vehicle",
        maxQuestions: MAX_QUESTIONS,
        fieldName: "vehicle",
        question: "What type of vehicle are you looking for?",
        answers: BREEZE_DOMAINS
            ? [
                {
                    key: "atv",
                    value: "ATV",
                },
                {
                    key: "watercraft",
                    value: "Watercraft",
                },
                {
                    key: "snowmobile",
                    value: "Snowmobile",
                },
                {
                    key: "trailer",
                    value: "Trailer",
                },
            ] : [
                {
                    key: "car",
                    value: "Car",
                },
                {
                    key: "truck",
                    value: "Truck",
                },
                {
                    key: "suv",
                    value: "SUV",
                },
                {
                    key: "mini-van",
                    value: "Mini Van",
                },
            ],
        // TODO: Temporary hack to get powersports unblocked
        dynamicField: "inventoryVertical",
        getDynamicConfig: getDynamicVehicleConfig,
    },
    {
        questionId: "interest-rate",
        maxQuestions: MAX_QUESTIONS,
        fieldName: "interestRate",
        question: "What is your current interest rate?",
        answers: [
            {
                key: "over-20",
                value: "Over 20%",
            },
            {
                key: "13-19.99",
                value: "Between 13 and 19.99%",
            },
            {
                key: "10-12.99",
                value: "Between 10 and 12.99%",
            },
            {
                key: "0-9.99",
                value: "Under 9.99%",
            },
        ],
    },
    // {
    //     questionId: "date-of-birth",
    //     maxQuestions: MAX_QUESTIONS,
    //     fieldName: "dob",
    //     question: "When were you born?",
    //     type: "date",
    // },
    {
        questionId: "employment",
        maxQuestions: MAX_QUESTIONS,
        fieldName: "employmentStatus",
        question: "What's your current employment status?",
        answers: [
            {
                key: "employed",
                value: "Employed",
            },
            {
                key: "self-employed",
                value: "Self-employed",
            },
            {
                key: "student",
                value: "Student",
            },
            {
                key: "other",
                value: "Other",
            },
        ],
    },
    // {
    //     questionId: "income",
    // maxQuestions: MAX_QUESTIONS,
    //     fieldName: "",
    //     question: "What is your current monthly income?",
    //     type: "number",
    // },
    {
        questionId: "employment-duration",
        maxQuestions: MAX_QUESTIONS,
        fieldName: "employmentDuration",
        question: "How long have you been earning this income?",
        answers: [
            {
                key: "over-2-years",
                value: "Over 2 Years",
            },
            {
                key: "3-months-2-years",
                value: "3 Months - 2 Years",
            },
            {
                key: "less-than-3-months",
                value: "Less than 3 months",
            },
        ],
    },
    {
        questionId: "budget",
        maxQuestions: MAX_QUESTIONS,
        fieldName: "monthlyBudget",
        question: "What is your monthly budget for your next vehicle?",
        answers: [
            {
                key: "over-500",
                value: "Over $500 / Month",
            },
            {
                key: "375-500",
                value: "$375 - $500 / Month",
            },
            {
                key: "250-375",
                value: "$250 - $375 / Month",
            },
            {
                key: "under-250-month",
                value: "Under $250 / Month",
            },
        ],
    },
    // {
    //     questionId: "employer",
    // maxQuestions: MAX_QUESTIONS,
    //     fieldName: "",
    //     question: "Great! Who is your employer?",
    //     fields: [
    //         {
    //             key: "Employer",
    //             type: "string",
    //         },
    //         {
    //             key: "Position",
    //             type: "string",
    //         },
    //     ],
    // },
    // {
    //     questionId: "address",
    // maxQuestions: MAX_QUESTIONS,
    //     fieldName: "",
    //     question: "Alright! What's your home address?",
    //     type: "address",
    // },
    {
        questionId: "address-duration",
        maxQuestions: MAX_QUESTIONS,
        fieldName: "addressDuration",
        question: "How long have you been living at this address?",
        answers: [
            {
                key: "over-2-years",
                value: "Over 2 Years",
            },
            {
                key: "less-than-2-years",
                value: "Less Than 2 Years",
            },
        ],
    },
    // {
    //     questionId: "name"
    // maxQuestions: MAX_QUESTIONS,
    //     fieldName: "",
    //     question: "What's your name?",
    //     fields: [
    //         {
    //             key: "First Name",
    //             type: "string",
    //         },
    //         {
    //             key: "Last Name",
    //             type: "string",
    //         },
    //     ],
    // },
    // {
    //     questionId: "email"
    // maxQuestions: MAX_QUESTIONS,
    //     fieldName: "",
    //     question: "What email would you like us to use?",
    //     type: "email",
    // },
    // {
    //     questionId: "phone"
    // maxQuestions: MAX_QUESTIONS,
    //     fieldName: "",
    //     question: "What's the best phone number to reach you?",
    //     type: "phone",
    // },
    // {
    //     questionId: "code"
    // maxQuestions: MAX_QUESTIONS,
    //     fieldName: "",
    //     question: "Enter the verification code sent to +1 (111) 111-1111 via SMS",
    //     type: "code",
    // },
];