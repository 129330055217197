import { gql } from "@api/api-client";

export const FetchNeighborhoodView = gql`
    mutation fetchNeighborhoodView($leadId: UUID!) {
        fetchNeighborhoodView(leadId: $leadId) {
            postalCode
            equifaxRiskScore
            street
        }
    }
`;