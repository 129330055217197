import { h, FunctionalComponent } from "preact";
import { useTranslation } from "react-i18next";

import kbbLogo from "@assets/powered-by-kbb-logo.png";
import cbbLogo from "@assets/cbb-logo.png";
import styles from "./TradeToolHeader.module.scss";

export interface TradeToolHeaderProps {
    provider?: "cbb" | "kbb";
}

export const TradeToolHeader = ({ provider }: TradeToolHeaderProps) => {
    const { i18n } = useTranslation();

    if (provider === "cbb") {
        return (
            <div className={styles.header}>
                <div className={styles.cbb}>
                    <img src={cbbLogo} alt="trade-tool-header-logo" />
                </div>
                <select
                    value={i18n.language}
                    onChange={(e) => i18n.changeLanguage(e.currentTarget.value)}
                    className={styles.langDropdown}
                >
                    <option value="en">🇨🇦 - English</option>
                    <option value="fr">🇨🇦 - French</option>
                </select>
            </div>
        );
    }

    return (
        <div className={styles.kbb}>
            <img src={kbbLogo} alt="trade-tool-header-logo" style={{ width: "100%" }} />
        </div>
    );
};
