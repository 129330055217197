import { h, FunctionalComponent } from "preact";
import { Portal, IPortalProps } from "./Portal";

export interface IOverlayProps extends Pick<IPortalProps, "placement"> {
    open: boolean
    noAutoScroll?: boolean;
    onClose?: () => void
    children: any;
}

const MODAL_ROOT = "modal-root-container";

export const Overlay: FunctionalComponent<IOverlayProps> = ({
    placement = "outer",
    open,
    noAutoScroll = false,
    onClose,
    children,
}) => {
    return (
        <>
            {open && (
                <Portal
                    nodeId={MODAL_ROOT}
                    onClose={onClose}
                    placement={placement}
                    resizeContainer={true}
                    noAutoScroll={noAutoScroll}
                >
                    {children}
                </Portal>
            )}
        </>
    );
};